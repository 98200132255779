import React, { useState } from 'react';
import {
    useLocation,
    useNavigate
} from 'react-router-dom';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import './css/login.css'
import request from '../utilities/api';
import SnackBar from '../component/snackbar';
import {success, error, warning} from '../component/message'

function LoginPage(){
    const [fieldValues, setFieldValues] = useState({})
    const [errors, setErrors] = useState({})
    const [snackBar, setSnackBar] = useState({open: false})

    const navigate = useNavigate()

    function setValue(key, value){
        const fields = JSON.parse(JSON.stringify(fieldValues));
        fields[key] = value
        setFieldValues(fields)
        const error = errors;
        delete error[key]
        setErrors(error)
    }

    function valuesValidation(){
        const error = {}
        if(!fieldValues.company_name){
            error["company_name"] = {
                is_error: true,
                error_text: "Company name is required"
            }
        }
        if(!fieldValues.company_email){
            error["company_email"] = {
                is_error: true,
                error_text: "Company Email is required"
            }
        }
        if(!fieldValues.password){
            error["password"] = {
                is_error: true,
                error_text: "Password is required"
            }
        }
        setErrors(error)
        return {is_valid: Object.keys(error).length ? false : true}
    }

    function onSubmit(){
        const {is_valid} = valuesValidation()
        
        if(!is_valid) return
        const payload = {
            params: fieldValues,
            method: 'post',
            url_key: 'login',
        }
        request(payload)
        .then(res => {
            const data = res.data
            localStorage.setItem('user_token', JSON.stringify(data.data))
            navigate('/')
        })
        .catch(err => {
            const res = err.response
            // setSnackBar({
            //     open: true,
            //     type: 'error',
            //     message: res.data.message
            // })
            error(res.data.message)
        })
    }

    function redirectToRegisterPage(){
        navigate('/register')
    }

    const handleClick = () => {
        setSnackBar({open: false});
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackBar({open: false});
    };


    return (
        <div className='login-base-container'>
            <div className='login-box-container'>
                <h1 className='login-text'>Login</h1>
                <TextField 
                    id="standard-basic" 
                    label="Company Name" 
                    variant="standard" 
                    error={errors.company_name ? errors.company_name.is_error : false}
                    helperText={errors.company_name ? errors.company_name.error_text : ""}
                    style={{width: "100%", marginBottom: 15}}
                    onChange={(event) => setValue("company_name", event.target.value)}
                />
                <TextField 
                    id="standard-basic" 
                    label="company_email" 
                    variant="standard" 
                    error={errors.company_email ? errors.company_email.is_error : false}
                    helperText={errors.company_email ? errors.company_email.error_text : ""}
                    style={{width: "100%", marginBottom: 15}}
                    onChange={(event) => setValue("company_email", event.target.value)}
                />
                <TextField 
                    id="standard-basic" 
                    label="Password" 
                    variant="standard" 
                    error={errors.password ? errors.password.is_error : false}
                    helperText={errors.password ? errors.password.error_text : ""}
                    style={{width: "100%", marginBottom: 25}}
                    type="password"
                    onChange={(event) => setValue("password", event.target.value)}
                />
                <Button variant="contained" className='login-button' onClick={() => onSubmit()}>Login</Button>
                <div className='register-text' onClick={() => redirectToRegisterPage()}>
                    Register?
                </div>
            </div>
            {/*<SnackBar snackBar={snackBar} handleClick={handleClick} handleClose={handleClose}/>*/}
        </div>
    )
}

export default LoginPage