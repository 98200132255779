import React from 'react'

function WithOutTaxBilling({
    billingObject,
    calculate_total_amount,
    calculate_cgst_tax_amount,
    calculate_sgst_tax_amount,
    calculate_total_tax_amount,
    calculate_final_amount,
    selectedCustomerDetails = {},
    selectedParts,
    selectedVehicleDetails
}){
    const formateAmount = (amount) => {
        return parseFloat(amount).toFixed(2)
    }

    console.log(selectedCustomerDetails, 'selctedcu')
    const {customer_name = "", customer_address = ""} = selectedCustomerDetails;
    return (
            <div className='bill-base-container' style={{border:'none'}}>
                <div className='date-bill-no-container w-full rounded-md'>
                    <div className='text-15 estimation-text'>ESTIMATION</div>
                    <div className='text-15'>BILL NO: {billingObject?.invoice_no ? billingObject.invoice_no : '____'}</div>
                    <div className="text-15">Date: {billingObject?.invoice_date ? billingObject.invoice_date : '____'}</div>
                </div>

                <div className='address-vehicle-no-container w-full rounded-md flex align-middle justify-between mt-1'>
                    <div className='text-15'>Mr.{billingObject?.customer_uid ? <u>{customer_name}</u> : '________________________'}</div>
                    <div className="text-15">Vehicle No: {selectedVehicleDetails ? <u style={{marginRight: '5px'}}>{selectedVehicleDetails?.vehicle_number}</u> : '________________'}</div>
                </div>

                <div className='table-header-container w-full rounded-md flex align-middle justify-between mt-1'>
                    <div className='text-12 fill-background-color mr-1 text-center rounded-md text-white p-1' style={{width: '10%'}}>S NO.</div>
                    <div className="text-12 fill-background-color mr-1 text-center rounded-md text-white p-1" style={{width: '70%'}}>DESCRIPTION</div>
                    <div className="text-12 fill-background-color text-center rounded-md text-white p-1" style={{width: '20%'}}>VALUE</div>
                </div>
                <div className='table-body-container w-full rounded-md flex align-middle justify-between'>
                    <div className='mr-1 text-center rounded-md border' style={{width: '10%'}}>
                        {Object.keys(selectedParts).map((x, i) => (
                            <div className="p-4">
                                {i+1}
                            </div>
                        ))}
                        <div></div>
                    </div>
                    <div className="mr-1 text-center rounded-md border" style={{width: '70%'}}>
                        {Object.keys(selectedParts).map((x) => {
                            const {part} = selectedParts[x]
                            return (
                                <div className="p-4">
                                    {part?.part_name}
                                </div>
                            )
                        })}
                        <div className='text-15 flex align-middle justify-end p-1'>Total</div>
                    </div>
                    <div className="text-center rounded-md border p-1" style={{width: '20%'}}>
                        {Object.keys(selectedParts).map((x) => {
                            const {total} = selectedParts[x]
                            return (
                                <div className="p-4">
                                    {total}
                                </div>
                            )
                        })}
                        <div className='text-15 total-cell'>
                        {formateAmount(billingObject && billingObject.round_off ? billingObject.round_off : billingObject.total_amount_after_tax ? billingObject.total_amount_after_tax: calculate_final_amount())}
                        </div>
                    </div>
                </div>
                <div className='bottom-container-without-tax flex align-middle justify-between mt-1'>
                    <div className='left rounded-md mr-1' style={{width: '70%'}}>
                        <div className='border p-1 rounded-md text-14 uppercase'>
                            Rupees
                        </div>
                        <div className='border p-3 mt-1 rounded-md'></div>
                    </div>
                    <div className='right rounded-md border' style={{width: '30%'}}>
                    </div>
                </div>
            </div>
    )
}

export default WithOutTaxBilling