import React, { useEffect, useState } from 'react';
import Select from 'react-select'
import moment from 'moment'
import request from '../utilities/api';
import { Button, Modal } from 'antd';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { ToWords } from 'to-words';

import LabourTaxBilling from './customer_component/billingComponent/labourTaxBilling';
import StandardBilling from './customer_component/billingComponent/standardBilling';
import WithOutTaxBilling from './customer_component/billingComponent/withOutTaxBilling';
import LabourBillingSetupComponent from './customer_component/billingSetupComponent/labourTaxBillingSetup';
import StandardBillingSetupComponent from './customer_component/billingSetupComponent/standardBillingSetup';
import WithOutTaxBillingSetupComponent from './customer_component/billingSetupComponent/withoutTaxBillingSetup';
import { error, success } from '../component/message';

const toWords = new ToWords({
    localeCode: 'en-IN',
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
      currencyOptions: { // can be used to override defaults for the selected locale
        name: 'Rupee',
        plural: 'Rupees',
        symbol: '₹',
        fractionalUnit: {
          name: 'Paisa',
          plural: 'Paise',
          symbol: '',
        },
      }
    }
  });


function BillingPage(){

    const [selectedParts, setSelectedParts] = useState({})
    const [customers, setCustomers] = useState([])
    const [customerOption, setCustomerOption] = useState([])
    const [vehicles, setVehicles] = useState([])
    const [vehicleOption, setVehicleOption] = useState([])
    const [billingObject, setBillingObject] = useState({
        invoice_no: '',
        invoice_date: moment().format('YYYY-MM-DD'),
        customer_uid: '',
        vehicle_uid: '',
        gst_no: '',
        rupees_in_text: '',
        bank_details: {
            bank_name: '',
            bank_account_no: '',
            bank_ifsc_code: ''
        },
        cgst_percentage: '',
        sgst_percentage: '',
        total_amount_before_tax: 0,
        cgst_tax_amount: 0,
        sgst_tax_amount: 0,
        total_tax_amount: 0,
        round_off: 0,
        total_amount_after_tax: 0,
        phone_number: "",
        secondary_phone_number: "9842251151"
    })
    const [loading, setLoading] = useState(false)
    const [selectedCustomerDetails, setSelectedCustomerDetails] = useState({})
    const [selectedVehicleDetails, setSelectedVehicleDetails] = useState({})
    const [billingType, setBillingType] = useState('standard_billing')
    const options = [
        { value: 'standard_billing', label: 'Standard Billing' },
        { value: 'labour_billing', label: 'Labour Billing' },
        { value: 'without_tax', label: 'Without Tax' }
      ]
    const [imgURL, setImgUrl] = useState('')
    const [documentModal, setDocumentModal] = useState(false)
    const [taxInfo, setTaxInfo] = useState({})
    const [itemInfo, setItemInfo] = useState({})
    const [invoice_uid, setInvoiceUid] = useState('')
    const [mail, setMail] = useState('')

    const formateAmount = (amt) => {
        return parseFloat(parseFloat(amt).toFixed(2))
    }

    useEffect(() => {
        load_initial_data()
    },[])

    useEffect(() => {
        // re_calculate_parts_total()
        re_calculate_tax()
        if(billingType == "labour_billing"){
            generate_tax_info_data()
        }
    },[billingType, selectedParts])

    useEffect(()=>{
        generate_number_to_word()
    },[billingObject])

    function generate_number_to_word(){
        if(!billingObject.total_amount_after_tax) return
        let words = toWords.convert(billingObject.total_amount_after_tax);
        const clone = {...billingObject}
        clone["rupees_in_text"] = words
        setBillingObject(clone)
    }

    async function load_initial_data(){
        const {company = {}} = get_company_details()
        const {customers = []} = await get_customers()
        // const {vehicles = []} = await get_vehicles()
        const {count = 0} = await get_invoice_count()
        const {company_gst_no = '', company_bank_details = {}, company_phone = ""} = company
        
        const clone = {...billingObject}
        clone["gst_no"] = company_gst_no
        clone["bank_details"] = company_bank_details
        clone["invoice_no"] = count
        clone["phone_number"] = company_phone

        setCustomerOption(customers)
        setBillingObject(clone)
        // setVehicleOption(vehicles)
    }

    function re_calculate_parts_total(){
        const clone = {...selectedParts}
        Object.keys(clone).forEach(x => {
            const data = clone[x]
            const {part = {}, total, quantity} = data
            clone[x].total = (part.part_price * quantity) || 0
        })
        setSelectedParts(clone)
    }

    function re_calculate_tax(){
        if(Object.keys(selectedParts).length){
            const total_amount_before_tax = calculate_total_amount()
            const cgst_tax_amount = calculate_cgst_tax_amount()
            const sgst_tax_amount = calculate_sgst_tax_amount()
            const total_tax_amount = calculate_total_tax_amount()
            const total_amount_after_tax = calculate_final_amount()
            console.log(total_amount_after_tax, 'total-amount_after_tax')
            const clone = {...billingObject}
            clone["total_amount_before_tax"] = total_amount_before_tax
            if(billingType == "standard_billing"){
                clone["cgst_percentage"] = 9
                clone["sgst_percentage"] = 9
                clone["cgst_tax_amount"] = cgst_tax_amount
                clone["sgst_tax_amount"] = sgst_tax_amount
                clone["total_tax_amount"] = total_tax_amount
            }
            else{
                clone["cgst_tax_amount"] = 0
                clone["sgst_tax_amount"] = 0
                clone["total_tax_amount"] = 0
            }
            clone["total_amount_after_tax"] = Math.round(total_amount_before_tax + clone["total_tax_amount"])
            setBillingObject(clone)
        }
    }

    function get_company_details(){
        const user_details = JSON.parse(localStorage.getItem('user_token'))
        return {company: user_details}
    }

    async function get_customers(info = {}){
        const {page = 1, search} = info
        const payload = {
            method: 'get',
            url_key: 'customers',
            params: {
                pagination: false
            }
        }
        try{
            const response = await request(payload)
            setCustomers(response.data.data)
            const data = response.data.data
            const arr = data.map(x => {
                return {label: x.customer_name, value: x._id}
            })
            return {customers: arr}
        }catch(err){
            return {customers: []}
        }
    }

    async function get_vehicles(info = {}) {
        const {page = 1, search} = info
        const payload = {
            method: 'get',
            url_key: 'vehicles',
            params: {
                ...info,
                pagination: false
            }
        }
        try{
            const response = await request(payload)
            setVehicles(response.data.data)
            const data = response.data.data
            const arr = data.map(x => {
                return {label: x.vehicle_number, value: x._id}
            })
            setVehicleOption(arr)
            // return {vehicles: arr}
        }
        catch(err){
            return {vehicles: []}
        }
    }

    async function get_invoice_count(){
        const payload = {
            method: 'get',
            url_key: 'vehicles-count',
            params: {}
        }
        try{
            const response = await request(payload)
            return {count: response.data.data.count + 1}
        }
        catch(err){
            return {count: 0}
        }
    }

    const base64toBlob = (data) => {
        const base64WithoutPrefix = data
        const bytes = atob(base64WithoutPrefix);
        let length = bytes.length;
        let out = new Uint8Array(length);
        while (length--) {
            out[length] = bytes.charCodeAt(length);
        }
        return new Blob([out], { type: 'application/pdf' });
    };

    const generate_invoice = () => {
        const payload = {
            method: 'post',
            url_key: 'invoice',
            params: {
                ...billingObject,
                parts: selectedParts,
                invoice_type: billingType,
                taxInfo: taxInfo
            }
        }
        setLoading(true)
        request(payload)
        .then(res => {
            const base64 = res.data.data.pdf
            const blob = base64toBlob(base64)
            const blobUrl = URL.createObjectURL(blob);

            if(res.data.data.invoice_uid){
                setInvoiceUid(res.data.data.invoice_uid)
            }
            setImgUrl(blobUrl)
            setDocumentModal(true)
            setLoading(false)
        })
        .catch(err => {
            const response = err.response
            error(response.data.message)
            setLoading(false)
        })
    }
    
    const toggleDocumentModal = () => {
        setDocumentModal(false);
        setImgUrl('');
    } 

    const setBillingDetails = (key, value) => {
        const clone = {...billingObject}
        clone[key] = parseFloat(parseFloat(value).toFixed(2))
        setBillingObject(clone)
    }

    const checkIsPartSelected = (part_uid) => {
        const part = selectedParts[part_uid]
        return part ? true : false
    }

    const onChangePart = (part_uid, part, quantity = 0, type) => {
        const clone_part_list = {...selectedParts}
        if(type == 'add'){ 
            const total = quantity * part.part_price
            const tax = total * part.part_tax_rate / 100
            clone_part_list[part_uid] = {
                part,
                quantity,
                total: parseFloat(parseFloat(total).toFixed(2)),
                total_amount_with_tax: Math.round(total + tax)
            }
        }
        else if(type == 'remove'){
            delete clone_part_list[part_uid]
        }
        setSelectedParts(clone_part_list)
    }

    const onChangeBillingFields = (key, value, info = 'other') => {
        const clone_data = {...billingObject}
        if(info == 'bank'){
            clone_data['bank_details'][key] = value
        }
        else if(info == 'other' || info == "customer" || info == "vehicle"){
            clone_data[key] = value
        }
        if(info == 'customer'){
            const find = customers.find(x => x._id == value)
            if(find){
                setSelectedCustomerDetails(find)
            }
            get_vehicles({filter_customer: value})
        }
        if(info == 'vehicle'){
            const find = vehicles.find(x => x._id == value)
            if(find){
                setSelectedVehicleDetails(find)
            }
        }
        if((key == 'cgst_percentage' || key == "sgst_percentage") && billingType != "labour_billing"){
            calculate_tax(clone_data)
        }
        else{
            setBillingObject({...clone_data})
        }
    }

    const calculate_tax = (clone_data) => {
        const cgst_amount = calculate_cgst_tax_amount(clone_data.cgst_percentage)
        const sgst_amount = calculate_sgst_tax_amount(clone_data.sgst_percentage)
        const total = parseFloat(cgst_amount) + parseFloat(sgst_amount)
        clone_data['cgst_tax_amount'] = cgst_amount
        clone_data['sgst_tax_amount'] = sgst_amount
        clone_data['total_tax_amount'] = total
        clone_data['total_amount_after_tax'] = clone_data.total_amount_before_tax + total
        setBillingObject({...clone_data})
    }

    const calculate_total_amount = () => {
        const total = Object.keys(selectedParts).reduce((a,b) => {
            const data = selectedParts[b]
            return a + data.total
        },0)
        return formateAmount(total)
    }

    const calculate_cgst_tax_amount = (tax) => {
        const total = parseFloat(calculate_total_amount())
        const percentage = tax || tax == 0 ? tax : billingObject.cgst_percentage
        return formateAmount(total * percentage / 100)
    }

    const calculate_sgst_tax_amount = (tax) => {
        const total = parseFloat(calculate_total_amount())
        const percentage = tax || tax == 0 ? tax : billingObject.sgst_percentage
        return formateAmount(total *  percentage / 100)
    }

    const calculate_total_tax_amount = () => {
        const total = parseFloat(calculate_cgst_tax_amount()) + parseFloat(calculate_sgst_tax_amount())
        return formateAmount(total)
    }

    const calculate_final_amount = () => {
        const total = parseFloat(calculate_total_amount())
        const tax = parseFloat(calculate_total_tax_amount()) || billingObject.total_tax_amount
        return formateAmount(Math.round(total + tax))
    }

    function generate_tax_info_data(){
        const clone = {...selectedParts}
        
        const object = Object.keys(clone).reduce((obj, key) => {
            const {part, quantity} = clone[key]
            if(obj[part.part_tax_rate]){
                obj[part.part_tax_rate].taxable_value += part.part_price * quantity
                obj[part.part_tax_rate].part.push(part)
            }else{
                obj[part.part_tax_rate] = {
                    taxable_value: part.part_price * quantity,
                    cgst:{
                        tax: parseInt(part.part_tax_rate) / 2,
                        amount: 0
                    },
                    sgst: {
                        tax: parseInt(part.part_tax_rate) / 2,
                        amount: 0
                    },
                    part: [
                        part
                    ]
                }
            }
            return obj
        }, {})

        const final_object = Object.keys({...object}).reduce((obj, cur) => {
            const {cgst, sgst, taxable_value} = obj[cur]
            obj[cur].cgst.amount = formateAmount(taxable_value * cgst.tax / 100)
            obj[cur].sgst.amount = formateAmount(taxable_value * sgst.tax / 100)
            obj[cur]['total_amount'] = formateAmount(obj[cur].cgst.amount + obj[cur].sgst.amount)
            return obj
        },object)

        const cgst_amount = Object.values(final_object).reduce((a,b) => {
            const {cgst = {}} = b
            return a + cgst.amount
        }, 0)
        const sgst_amount = Object.values(final_object).reduce((a,b) => {
            const {sgst = {}} = b
            return a + sgst.amount
        }, 0)

        const total_amount = Object.values(final_object).reduce((a,b) => {
            return a + b.total_amount
        }, 0)
        const clone_billing_details = {...billingObject}
        clone_billing_details["cgst_tax_amount"] = parseFloat(parseFloat(cgst_amount).toFixed(2))
        clone_billing_details["sgst_tax_amount"] = parseFloat(parseFloat(sgst_amount).toFixed(2))
        clone_billing_details["total_tax_amount"] = parseFloat(parseFloat(sgst_amount + cgst_amount).toFixed(2))
        clone_billing_details["total_amount_after_tax"] = Math.round(parseFloat(parseFloat(clone_billing_details.total_amount_before_tax + total_amount).toFixed(2)))

        clone_billing_details["cgst_percentage"] = 0
        clone_billing_details["sgst_percentage"] = 0
        setItemInfo(final_object)
        setTaxInfo(final_object)
        setBillingObject(clone_billing_details)
    }

    function send_invoice_to_mail(){
        if(!mail){
            return error('mail id is missing')
        }
        else if(!invoice_uid){
            return error('invoice uid is missing')
        }

        const payload = {
            method: 'post',
            url_key: 'invoice-mail',
            params: {
                invoice_uid,
                email: mail
            }
        }
        request(payload)
        .then(res => {
            success(res.data.message)
        })
        .catch(err => {
            const response = err.response
            error(response.data.message)
        })

    }

    const generateBillComponent = () => {
        switch(billingType){
            case 'standard_billing':
                return <StandardBilling
                    billingObject={billingObject}
                    calculate_total_amount={calculate_total_amount}
                    calculate_cgst_tax_amount={calculate_cgst_tax_amount}
                    calculate_sgst_tax_amount={calculate_sgst_tax_amount}
                    calculate_total_tax_amount={calculate_total_tax_amount}
                    calculate_final_amount={calculate_final_amount}
                    selectedCustomerDetails={selectedCustomerDetails}
                    selectedParts={selectedParts}
                    billingType={billingType}
                    vehicleOption={vehicleOption}
                    selectedVehicleDetails={selectedVehicleDetails}
                />
            case 'labour_billing':
                return <LabourTaxBilling
                    billingObject={billingObject}
                    calculate_total_amount={calculate_total_amount}
                    calculate_cgst_tax_amount={calculate_cgst_tax_amount}
                    calculate_sgst_tax_amount={calculate_sgst_tax_amount}
                    calculate_total_tax_amount={calculate_total_tax_amount}
                    calculate_final_amount={calculate_final_amount}
                    selectedCustomerDetails={selectedCustomerDetails}
                    selectedParts={selectedParts}
                    billingType={billingType}
                    vehicleOption={vehicleOption}
                    selectedVehicleDetails={selectedVehicleDetails}
                    setTaxInfo={setTaxInfo}
                    itemInfo={itemInfo}
                />
            case 'without_tax':
                return <WithOutTaxBilling
                    billingObject={billingObject}
                    calculate_total_amount={calculate_total_amount}
                    calculate_cgst_tax_amount={calculate_cgst_tax_amount}
                    calculate_sgst_tax_amount={calculate_sgst_tax_amount}
                    calculate_total_tax_amount={calculate_total_tax_amount}
                    calculate_final_amount={calculate_final_amount}
                    selectedCustomerDetails={selectedCustomerDetails}
                    selectedParts={selectedParts}
                    billingType={billingType}
                    vehicleOption={vehicleOption}
                    selectedVehicleDetails={selectedVehicleDetails}
                />
            default:
                return <StandardBilling
                    billingObject={billingObject}
                    calculate_total_amount={calculate_total_amount}
                    calculate_cgst_tax_amount={calculate_cgst_tax_amount}
                    calculate_sgst_tax_amount={calculate_sgst_tax_amount}
                    calculate_total_tax_amount={calculate_total_tax_amount}
                    calculate_final_amount={calculate_final_amount}
                    selectedCustomerDetails={selectedCustomerDetails}
                    selectedParts={selectedParts}
                    billingType={billingType}
                    vehicleOption={vehicleOption}
                    selectedVehicleDetails={selectedVehicleDetails}
                />

        }
    }

    const generateBillSetupComponent = () => {
        // switch(billingType){
        //     case 'standard_billing':
        //         return <StandardBillingSetupComponent/>
        //     case 'labour_billing':
        //         return <LabourBillingSetupComponent/>
        //     case 'without_tax':
        //         return <WithOutTaxBillingSetupComponent/>
        //     default:
        //         return <StandardBillingSetupComponent/>

        // }
        return <StandardBillingSetupComponent 
        selectedParts={selectedParts} 
        checkIsPartSelected={checkIsPartSelected} 
        onChangePart={onChangePart}
        onChangeBillingFields={onChangeBillingFields}
        billingObject={billingObject}
        customerOption={customerOption}
        calculate_total_amount={calculate_total_amount}
        calculate_cgst_tax_amount={calculate_cgst_tax_amount}
        calculate_sgst_tax_amount={calculate_sgst_tax_amount}
        calculate_total_tax_amount={calculate_total_tax_amount}
        calculate_final_amount={calculate_final_amount}
        setSelectedParts={setSelectedParts}
        billingType={billingType}
        vehicleOption={vehicleOption}
        selectedVehicleDetails={selectedVehicleDetails}
        generate_invoice={generate_invoice}
        setBillingDetails={setBillingDetails}
        loading={loading}
        />
    }

    console.log(billingObject, 'billing - object')
    return (
        <div className='app-module-container'>
            {<div className='app-module-appbar'>
                <div className='app-module-appbar-title'>
                    <h1>Billing</h1>
                </div>
                { 
                    <div className='flex' style={{alignItems: 'center', justifyContent: 'center', width: '200px'}}>
                        <Select className='custom-select-field' options={options} defaultValue={{value: 'standard_billing', label: 'Standard Billing'}} styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              width: '100%'
                            }),
                          }}
                          onChange={(e) => setBillingType(e.value)}
                          />
                    </div>
                }
            </div>}
            <div className='app-module-body-billing'>
                <div className='billing-container'>
                    <div className='billing-left-container'>
                        {generateBillSetupComponent()}
                    </div>
                    <div className='billing-right-container'>
                        <div className='bill-preview'>
                            {generateBillComponent()}
                        </div>
                    </div>
                </div>
            </div>
            <Modal 
                open={documentModal} 
                centered size="lg" 
                onOk={toggleDocumentModal} 
                onCancel={toggleDocumentModal}
                footer={[
                    <div style={{ width:'100%', padding: 10, display:'flex', alignItems:'center',justifyContent:'space-between' }}>
                        <div style={{display:'flex', alignItems: 'center', justifyContent:'space-between', width:'80%'}}>
                            <input className="custom-input-field" style={{width: '68%', height: '30px'}} placeholder="enter email" onChange={(e) => setMail(e.target.value)}/>
                            <Button variant="contained" className='login-button' style={{width: '30%', color:'white'}} onClick={() => send_invoice_to_mail()}>Send</Button>
                        </div>
                        <div style={{width:'20%', display:'flex', alignItems:'center',justifyContent:'center'}}>
                            <a href={imgURL} download='invoice'>
                                <Tooltip title="Download">
                                    <IconButton onClick={() => {
                                    }}>
                                        <CloudDownloadIcon style={{color: 'rgba(0,0,0, 0.5)'}}/>
                                    </IconButton>
                                </Tooltip>
                            </a>
                        </div>
                    </div>
                ]}
                >
                <iframe
                    src={imgURL}
                    style={{ width: "100%", height: '500px' }}
                    frameborder="0"
                />
            </Modal>
        </div>
    )
}

export default BillingPage