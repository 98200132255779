import React, { useState, useEffect } from 'react';
import { Input } from 'antd';
import {useNavigate} from 'react-router-dom';
import { IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import TableComponent from '../component/table';
import VehicleDetailsPage from './customer_component/vehicleDetails';
import Button from '@mui/material/Button';
import request from '../utilities/api';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {error, success, warning} from '../component/message'
import AlertDialogSlide from '../component/confirmation_modal';



function VehiclePage(){

    const [response, setResponse] = useState({})
    const [customers, setCustomers] = useState([])
    const [customerOption, setCustomerOption] = useState([])
    const [actionType, setActionType] = useState("")
    const [selectedVehicle, setselectedVehicle] = useState({})
    const [openSlide, setOpenSlide] = useState(false)
    const [slideMessage, setSlideMessage] = useState('')
    const [selectedCustomerDelete, setSelectedCustomerDelete] = useState({})

    const columns = [
        {
            name: 'S.NO',
            selector: (row, index) => index + 1,
        },
        {
            name: 'Vehicle Unique ID',
            selector: row => row.vehicle_unique_id,
        },
        {
            name: 'Vehicle Number',
            selector: row => row.vehicle_number,
        },
        {
            name: 'Brand',
            selector: row => row.vehicle_brand,
        },
        {
            name: 'Modal',
            selector: row => row.vehicle_model,
        },
        {
            name: 'Customer',
            selector: row => row.customer_uid?.customer_name,
        },
        {
            name: 'Action',
            selector: row => (
                <>
                    <IconButton onClick={() => {
                        setselectedVehicle(row)
                        setActionType('view')
                    }}>
                        <VisibilityIcon/>
                    </IconButton>
                    <IconButton onClick={() => {
                        setSlideMessage(`Are you sure want delete ${row.vehicle_number}`)
                        setSelectedCustomerDelete(row)
                        toggleOpenSlide()
                    }}>
                        <DeleteOutlineIcon style={{color: 'rgba(255,0,0,0.5)', size: '10px'}}/>
                    </IconButton>
                </>
            )
        }
    ];

    const naviagate = useNavigate()

    useEffect(() => {
        get_vehicles()
    },[])

    useEffect(() => {
        get_customers()
    },[actionType])

    const toggleOpenSlide = () => {
        setOpenSlide(!openSlide)
    }

    const get_vehicles = (info = {}) => {
        const {page = 1, search} = info
        const payload = {
            method: 'get',
            url_key: 'vehicles',
            params: {
                limit: 10,
                page: page,
                search
            }
        }
        request(payload)
        .then(res => {
            setResponse(res.data.data)
        })
        .catch(err => {
            console.log(err, '--err')
        })
    }

    const onSearchText = (payload) => {
        get_vehicles({search: payload})
    }

    const updateVehicle = (payload) => {
        const api_payload = {
            method: 'put',
            url_key: 'update_vehicle',
            params: payload,
            _id: selectedVehicle._id
        }
        request(api_payload)
        .then(res => {
            const response = res.data
            get_vehicles()
            setActionType("")
            success(response.message)
        })
        .catch(err => {
            console.log(err, 'err')
            const api_response = err.response?.data
            error(api_response.message)
        })
    }

    const addNewButtonSubmit = () => {
        setActionType("add")
    }

    const get_customers = (info = {}) => {
        const {page = 1, search} = info
        const payload = {
            method: 'get',
            url_key: 'customers',
            params: {
                pagination: false
            }
        }
        request(payload)
        .then(res => {
            setCustomers(res.data.data)
            const data = res.data.data
            const arr = data.map(x => {
                return {label: x.customer_name, value: x._id}
            })
            setCustomerOption(arr)
        })
        .catch(err => {
            console.log(err, '--err')
        })
    }

    const addNewVehicle = (payload) => {
        const api_payload = {
            method: 'post',
            url_key: 'vehicles',
            params: payload
        }
        request(api_payload)
        .then(res => {
            const response = res.data
            get_vehicles()
            setActionType("")
            success(response.message)
        })
        .catch(err => {
            const api_response = err.response?.data
            error(api_response.message)
        })
    }

    const removeCustomer = () => {
        const {_id} = selectedCustomerDelete
        request({
            method: 'delete',
            url_key: 'delete_vehicle',
            params: {},
            _id
        }).then(res => {
            const api_response = res.data
            setOpenSlide(false)
            setSlideMessage('')
            setSelectedCustomerDelete({})
            get_vehicles()
            success(api_response.message)
        })
        .catch(err => {
            const api_response = err.response
            error(api_response.data.message)
        })
    }


    const generateBodyComponent = () => {
        switch(actionType){
            case "add":
                return <VehicleDetailsPage customerOption={customerOption} actionType={actionType} setActionType={setActionType} addNewVehicle={addNewVehicle}/>
            case "view":
                return <VehicleDetailsPage customerOption={customerOption} actionType={actionType} setActionType={setActionType} selectedVehicle={selectedVehicle} updateVehicle={updateVehicle}/>
            default:
                return <TableComponent 
                details={response}
                columns={columns} 
                tableData={response.docs} 
                setselectedVehicle={setselectedVehicle} 
                setActionType={setActionType} 
                getDetails={get_vehicles}
                expandable={false}/>
        }
    }

    return (
        <div className='app-module-container'>
            {(actionType != 'view' && actionType != 'add') && <div className='app-module-appbar'>
                <div className='app-module-appbar-title'>
                    <h1>{actionType == "view" ? "Vehicle Details" : "Vehicle List" }</h1>
                </div>
                {(actionType != 'view' && actionType != 'add') && 
                    <div className='flex' style={{alignItems: 'center', justifyContent: 'center'}}>
                        <Input.Search
                            className={"search-input-box"}
                            placeholder="Search with vehicle number"
                            allowClear={true}
                            onSearch={onSearchText}
                        />
                        <Button variant="contained" className='login-button' style={{minWidth: '100px', marginLeft: 10}} onClick={() => addNewButtonSubmit()}>Add New</Button>
                    </div>
                }
            </div>}
            <div className='app-module-body'>
                {
                    generateBodyComponent()
                }
            </div>
            <AlertDialogSlide open={openSlide} setOpen={toggleOpenSlide} message={slideMessage} onSubmit={removeCustomer}/>
        </div>
    )
}

export default VehiclePage