import React, { useState } from 'react';
import {
    useNavigate
} from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import './css/login.css'
import request from '../utilities/api';

function RegisterPage(){
    const [fieldValues, setFieldValues] = useState({})
    const [errors, setErrors] = useState({})

    const navigate = useNavigate()

    function setValue(key, value){
        const fields = JSON.parse(JSON.stringify(fieldValues));
        fields[key] = value
        setFieldValues(fields)
        const error = errors;
        delete error[key]
        setErrors(error)
    }

    function valuesValidation(){
        const error = {}
        if(!fieldValues.company_name){
            error["company_name"] = {
                is_error: true,
                error_text: "Company name is required"
            }
        }
        if(!fieldValues.company_email){
            error["company_email"] = {
                is_error: true,
                error_text: "Company email is required"
            }
        }
        if(!fieldValues.password){
            error["password"] = {
                is_error: true,
                error_text: "Password is required"
            }
        }
        if(!fieldValues.confirm_password){
            error["confirm_password"] = {
                is_error: true,
                error_text: "Confirm Password is required"
            }
        }
        // if(!fieldValues.company_address){
        //     error["company_address"] = {
        //         is_error: true,
        //         error_text: "Comapany address is required"
        //     }
        // }
        setErrors(error)
        return {is_valid: Object.keys(error).length ? false : true}
    }
 
    function onSubmit(){
        const {is_valid} = valuesValidation()
        
        if(!is_valid) return

        delete fieldValues.confirm_password
        const payload = {
            method: 'post',
            url_key: 'register',
            params: fieldValues
        }
        request(payload)
        .then(res => {
            localStorage.setItem('user_token', JSON.stringify(res.data.data))
            navigate('/')
        })
        .catch(err => {
            console.log(err)
        })
    }

    function redirectToLoginPage(){
        navigate('/login')
    }


    return (
        <div className='login-base-container'>
            <div className='login-box-container'>
                <h1 className='login-text'>Register</h1>
                <TextField 
                    id="standard-basic" 
                    label="Company Name" 
                    variant="standard" 
                    error={errors.company_name ? errors.company_name.is_error : false}
                    helperText={errors.company_name ? errors.company_name.error_text : ""}
                    style={{width: "100%", marginBottom: 15}}
                    onChange={(event) => setValue("company_name", event.target.value)}
                />
                <TextField 
                    id="standard-basic" 
                    label="Company email"
                    variant="standard" 
                    error={errors.company_email ? errors.company_email.is_error : false}
                    helperText={errors.company_email ? errors.company_email.error_text : ""}
                    style={{width: "100%", marginBottom: 15}}
                    onChange={(event) => setValue("company_email", event.target.value)}
                />
                <TextField 
                    id="standard-basic" 
                    label="Password" 
                    variant="standard" 
                    error={errors.password ? errors.password.is_error : false}
                    helperText={errors.password ? errors.password.error_text : ""}
                    style={{width: "100%", marginBottom: 15}}
                    type="password"
                    onChange={(event) => setValue("password", event.target.value)}
                />
                <TextField 
                    id="standard-basic" 
                    label="Confirm Password"
                    variant="standard"
                    error={errors.confirm_password ? errors.confirm_password.is_error : false}
                    helperText={errors.confirm_password ? errors.confirm_password.error_text : ""}
                    style={{width: "100%", marginBottom: 25}}
                    type="password"
                    onChange={(event) => setValue("confirm_password", event.target.value)}
                />
                <TextField
                    id="outlined-multiline-static"
                    label="Company Address"
                    multiline
                    rows={4}
                    error={errors.company_address ? errors.company_address.is_error : false}
                    helperText={errors.company_address ? errors.company_address.error_text : ""}
                    style={{width: "100%", marginBottom: 15}}
                    onChange={(event) => setValue("company_address", event.target.value)}
                />
                <Button variant="contained" className='login-button' onClick={() => onSubmit()}>Register</Button>
                <div className='register-text' onClick={() => redirectToLoginPage()}>
                    Login?
                </div>
            </div>
        </div>
    )
}

export default RegisterPage