import React, { useEffect, useState } from "react";
import {
    useNavigate
} from 'react-router-dom'
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import StoreIcon from '@mui/icons-material/Store';
import ReceiptIcon from '@mui/icons-material/Receipt';

function SideBar({sideBarType}){
    const navigate = useNavigate()
    const [activeTab, setActiveTab] = useState('dashboard')

    const route = [
        {
            name: 'dashboard',
            route: '/',
            is_home: true,
            lable: 'Dashboard',
            icon: HomeIcon
        },
        {
            name: 'customer',
            route: '/customer',
            lable: 'Customer',
            icon: PersonIcon
        },
        {
            name: 'vehicle',
            route: '/vehicle',
            lable: 'Vehicle',
            icon: DriveEtaIcon
        },
        {
            name: 'part',
            route: '/part',
            lable: 'Part',
            icon: ProductionQuantityLimitsIcon
        },
        {
            name: 'billing',
            route: '/billing',
            lable: 'Billing',
            icon: ReceiptIcon
        },
        {
            name: 'company_details',
            route: '/company-details',
            lable: 'Company Details',
            icon: StoreIcon
        },
    ]

    const path = {
        "/": "dashboard",
        "/customer": "customer",
        "/vehicle": "vehicle",
        "/part": "part",
        "/billing": "billing",
        "/company-details": "company_details"
    }

    const location = window.location.toString()
    const location_split = location.split('/')
    const path_name = window.location.pathname

    useEffect(() => {
        setActiveTab(path[path_name])
    }, [])

    function naviagateToRoute(route){
        navigate(route.route)
        setActiveTab(route.name)
    }

    function generateClassName({name}){
        if (name == activeTab && sideBarType != "min"){
            return 'active-tab'
        }
        return 'side-bar-tab'
    }

    console.log(activeTab, 'active tab')

    return (
        <div>
            {route.map((r, index) => (
                <div key={index} className={generateClassName(r)} onClick={() => naviagateToRoute(r)}>
                    {sideBarType == 'min' ?
                        (
                            <Tooltip title={r.lable}>
                                <IconButton>
                                    <r.icon style={{color: r.name == activeTab ? "rgba(0, 0, 255, 0.5)" : ""}}/>
                                </IconButton>
                            </Tooltip>
                        )
                        : (<>{r.lable}</>)
                    }
                </div>
            ))}
        </div>
    )
}

export default SideBar