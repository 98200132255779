import React, { useEffect, useState } from 'react';
import { Input } from 'antd';
import {useNavigate} from 'react-router-dom';
import TableComponent from '../component/table';
import CustomerDetailsPage from './customer_component/customerDetails';
import { IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Button from '@mui/material/Button';
import request from '../utilities/api';
import {error, success, warning} from '../component/message'
import AlertDialogSlide from '../component/confirmation_modal';


function CustomerPage(){
    const [response, setResponse] = useState({})
    const [actionType, setActionType] = useState("")
    const [selectedUser, setSelectedUser] = useState({})
    const [openSlide, setOpenSlide] = useState(false)
    const [slideMessage, setSlideMessage] = useState('')
    const [selectedCustomer, setSelectedCustomer] = useState({})

    const columns = [
        {
            name: 'S.NO',
            selector: (row, index) => index + 1,
        },
        {
            name: 'Customer Unique ID',
            selector: row => row.customer_unique_id,
        },
        {
            name: 'Customer Name',
            selector: row => row.customer_name,
        },
        {
            name: 'Customer Phone',
            selector: row => row.customer_phone,
        },
        {
            name: 'Customer Email',
            selector: row => row.customer_email,
        },
        {
            name: 'Action',
            selector: row => (
                <>
                    <IconButton onClick={() => {
                        setSelectedUser(row)
                        setActionType('view')
                    }}>
                        <VisibilityIcon/>
                    </IconButton>
                    <IconButton onClick={() => {
                        setSlideMessage(`Are you sure want delete ${row.customer_name}`)
                        setSelectedCustomer(row)
                        toggleOpenSlide()
                    }}>
                        <DeleteOutlineIcon style={{color: 'rgba(255,0,0,0.5)', size: '10px'}}/>
                    </IconButton>
                </>
            )
        }
    ];

    const naviagate = useNavigate()

    const get_customers = (info = {}) => {
        const {page = 1, search} = info
        const payload = {
            method: 'get',
            url_key: 'customers',
            params: {
                limit: 10,
                page: page,
                search
            }
        }
        request(payload)
        .then(res => {
            setResponse(res.data.data)
        })
        .catch(err => {
            console.log(err, '--err')
        })
    }

    const toggleOpenSlide = () => {
        setOpenSlide(!openSlide)
    }

    useEffect(() => {
        get_customers()
        // create_customers()
    },[])

    const onSearchText = (payload) => {
        get_customers({search: payload})
    }

    const removeCustomer = () => {
        const {_id} = selectedCustomer
        request({
            method: 'delete',
            url_key: 'delete_customer',
            params: {},
            _id
        }).then(res => {
            const api_response = res.data
            setOpenSlide(false)
            setSlideMessage('')
            setSelectedCustomer({})
            get_customers()
            success(api_response.message)
        })
        .catch(err => {
            const api_response = err.response
            error(api_response.data.message)
        })
    }

    const updateCustomer = (payload) => {
        console.log(payload, 'update customer')
        const {_id, ...rest} = payload
        request({
            method: 'put',
            url_key: 'update_customer',
            params: {...rest},
            _id
        })
        .then(res => {
            const api_response = res.data
            get_customers()
            success(api_response.message)
        })
        .catch(err => {
            const api_response = err.response
            error(api_response.data.message)
        })
        // const clone_data = [...filteredData]
        // const find_index = clone_data.findIndex(x => x.customer_unique_id == payload.customer_unique_id)
        // clone_data[find_index] = payload
        // setFilteredData(clone_data)
        // setActionType("")
    }


    const generateBodyComponent = () => {
        switch(actionType){
            case "add":
                return <CustomerDetailsPage actionType={actionType} setActionType={setActionType} addNewCustomer={addNewCustomer}/>
            case "view":
                return <CustomerDetailsPage actionType={actionType} setActionType={setActionType} selectedUser={selectedUser} updateCustomer={updateCustomer}/>
            default:
                return <TableComponent 
                details={response} 
                tableData={response.docs} 
                columns={columns} 
                setSelectedUser={setSelectedUser} 
                setActionType={setActionType} 
                // expandable={true}
                getDetails={get_customers}
                />
        }
    }

    const addNewButtonSubmit = () => {
        setActionType("add")
    }

    const create_customers = () => {
        let promise = []
        for(let i = 5; i < 20; i++){
            const payload = {
                "customer_unique_id": `CU-00${i}`,
                "customer_name": `customer${i}`,
                "customer_email": `customer${i}@gmail.com`,
                "customer_phone": "7686855959",
                "customer_address": `customer${i} address`
            }
            const req = request({
                method: 'post',
                url_key: 'customers',
                params: payload
            })

            promise.push(req)
        }

        Promise.all(promise)
        .then(res => {
            console.log(res)
        })
        .catch(err => {
            console.log(err, 'er')
        })
        
    }

    const addNewCustomer = (payload) => {
        console.log(payload, 'add payload')
        const body_payload = {
            method: 'post',
            url_key: 'customers',
            params: payload
        }
        request(body_payload)
        .then(res => {
            const api_response = res.data
            console.log(res, 'res')
            // const customers = {...response}
            // customers.docs.push(api_response.data)
            // setResponse(customers)
            get_customers()
            setActionType("")
            success(api_response.message)
        })
        .catch(err => {
            const api_response = err.response
            error(api_response.data.message)
        })
        // const data_ = [...filteredData]
        // data.push(payload)
        // const data1 = [...data]
        // data_.push(payload)
        // setFilteredData((f) => f.push(payload))
        // setFilteredData(data_)
        // setData(data1)
        // setData((d) => d.push(payload))
        // setActionType("")
    }

    return (
        <div className='app-module-container'>
            {(actionType != 'view' && actionType != 'add') && <div className='app-module-appbar'>
                <div className='app-module-appbar-title'>
                    <h1>{actionType == "view" ? "Customer Details" : "Customer List" }</h1>
                </div>
                {(actionType != 'view' && actionType != 'add') && 
                    <div className='flex' style={{alignItems: 'center', justifyContent: 'center'}}>
                    <Input.Search
                    className={"search-input-box"}
                    placeholder="Search with name / email"
                    allowClear={true}
                    onSearch={onSearchText}
                    />
                    <Button variant="contained" className='login-button' style={{minWidth: '100px', marginLeft: 10}} onClick={() => addNewButtonSubmit()}>Add New</Button>
                    </div>
                }
            </div>}
            <div className='app-module-body'>
                {
                    generateBodyComponent()
                }
            </div>
            <AlertDialogSlide open={openSlide} setOpen={toggleOpenSlide} message={slideMessage} onSubmit={removeCustomer}/>
        </div>
    )
}

export default CustomerPage