import React, { useState } from 'react';
import {
    useLocation,
    useNavigate
} from 'react-router-dom';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import './css/login.css'
import request from '../utilities/api';
import {success, error, warning} from '../component/message'

function ChangePassword() {
    const [fieldValues, setFieldValues] = useState({})
    const [errors, setErrors] = useState({})
    const navigate = useNavigate()

    const params = new URLSearchParams(window.location.search)

    function setValue(key, value){
        const fields = JSON.parse(JSON.stringify(fieldValues));
        fields[key] = value
        setFieldValues(fields)
        const error = errors;
        delete error[key]
        setErrors(error)
    }

    function valuesValidation(){
        const error_obj = {}
        if(!fieldValues.password){
            error_obj["password"] = {
                is_error: true,
                error_text: "Password is required"
            }
        }
        if(!fieldValues.confirm_password){
            error_obj["confirm_password"] = {
                is_error: true,
                error_text: "Confirm Password is required"
            }
        }
        if(fieldValues.password != fieldValues.confirm_password){
            error('Password And Confirm Password Should Be Same')
            error_obj["1"] = ""
        }
        setErrors(error_obj)
        return {is_valid: Object.keys(error_obj).length ? false : true}
    }

    function onSubmit(){
        const {is_valid} = valuesValidation()
        
        if(!is_valid) return
        const payload = {
            params: {...fieldValues, email: params.get('email')},
            method: 'post',
            url_key: 'change-password',
        }
        request(payload)
        .then(res => {
            success('Password Changed')
            setTimeout(() => {
                navigate('/')
            },[3000])
        })
        .catch(err => {
            const res = err.response
            error(res.data.message)
        })
    }

    function redirectToRegisterPage(){
        navigate('/register')
    }

    return (
        <div className='login-base-container'>
            <div className='login-box-container'>
                <h1 className='login-text'>Change Password</h1>
                <TextField 
                    id="standard-basic" 
                    label="Password" 
                    variant="standard" 
                    error={errors.password ? errors.password.is_error : false}
                    helperText={errors.password ? errors.password.error_text : ""}
                    style={{width: "100%", marginBottom: 15}}
                    onChange={(event) => setValue("password", event.target.value)}
                />
                <TextField 
                    id="standard-basic" 
                    label="Confirm Password" 
                    variant="standard" 
                    error={errors.confirm_password ? errors.confirm_password.is_error : false}
                    helperText={errors.confirm_password ? errors.confirm_password.error_text : ""}
                    style={{width: "100%", marginBottom: 15}}
                    onChange={(event) => setValue("confirm_password", event.target.value)}
                />
                <Button variant="contained" className='login-button' onClick={() => onSubmit()}>Submit</Button>
                <div className='register-text' onClick={() => redirectToRegisterPage()}>
                    Register?
                </div>
            </div>
            {/*<SnackBar snackBar={snackBar} handleClick={handleClick} handleClose={handleClose}/>*/}
        </div>
    )
}

export default ChangePassword