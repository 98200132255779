import {useState} from 'react';
import DataTable from 'react-data-table-component';
import { IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PopOverComponent from './popover';



function TableComponent({columns, tableData, setActionType, setSelectedUser, expandable, details, getDetails}) {

    const {docs = [], limit = 10, totalPages = 1, page = 1, totalDocs = 1} = details
    // A super simple expandable component.
    const ExpandedComponent = ({ data }) => <pre>
        <div style={{padding: '20px'}}>
            <h1>Customer Address:</h1>
            <br></br>
            <div>
                <p>Vinoba Nagar, Thalavai Pettai, <br></br> Bhavani, Erode - 638312.</p>
            </div>
        </div>
    </pre>;

    const OnChange = (data) => {
        console.log(data, 'data')
        getDetails({page: data})
    }

    return (
        <DataTable
            columns={columns}
            data={docs}
            expandableRows={expandable}
            expandableRowsComponent={ExpandedComponent}
            pagination={true}
            paginationServer
            // paginationServerOptions={}
            paginationTotalRows={totalDocs}
            onChangePage={(p) => OnChange(p)}
            // onChangeRowsPerPage={OnChange}
        />
    );
};

export default TableComponent