import React, { useEffect, useState } from 'react';
import {Input} from 'antd'
import {useNavigate} from 'react-router-dom';
import { useForm } from "react-hook-form";
import Button from '@mui/material/Button';
import { IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


function PartDetailsPage({actionType, setActionType, selectedPart, updatePart, addNewPart}){

    const [fieldsDisabled, setFieldsDisabled] = useState(false)
    const [editable, setEditable] = useState(false)
    const [partDetails, setPartDetails] = useState({...selectedPart})

    const { register, handleSubmit, setValue, watch, control, clearErrors, formState: { errors } } = useForm();

    console.log(errors, 'errors')

    useEffect(() => {
        if(actionType == "view"){
            setFieldsDisabled(true)
        }else if(actionType == "edit"){
            setFieldsDisabled(false)
        }
    },[actionType])

    function toggleEditable(e){
        console.log('edit')
        e.preventDefault()
        if(actionType == 'add'){
            goBack()
            return
        }
        if(editable){
            setFieldsDisabled(true)
        }else{
            setFieldsDisabled(false)
        }
        setEditable(!editable)
    }

    function goBack(){
        setActionType("")
    }

    function resetDetails(e){
        e.preventDefault()
        setPartDetails({...selectedPart})
        setValue('part_unique_id', selectedPart.part_unique_id)
        setValue('part_name', selectedPart.part_name)
        setValue('part_quantity', selectedPart.part_quantity)
        setValue('part_price', selectedPart.part_price)
        setValue('part_tax_rate', selectedPart.part_tax_rate)
        setValue('part_hsn_code', selectedPart.part_hsn_code)
        clearErrors()
    }

    function editFields(key, value){
        const clone_data = {...partDetails}
        clone_data[key] = value
        setPartDetails(clone_data)
        setValue(key, value)
        clearErrors()
    }

    console.log(partDetails, 'partDetails')

    const onSubmit = (payload, e) => {
        if(actionType == 'add'){
            addNewPart(payload)
            return
        }
        updatePart(payload)
        console.log(payload, 'payload')
    }

    return (
        <div className='h-full w-full flex align-middle justify-center flex-col'>
            <div>
                <IconButton onClick={() => goBack()}>
                    <ArrowBackIcon/>
                </IconButton>
            </div>
            <div className='customer-details-container mt-4'>
                <h1>{actionType == 'add' ? 'Add New Part' : 'Part Details'}</h1>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='grid grid-cols-2' style={{gridGap: '10px'}}>
                        <div>
                            <div style={{fontSize:13, marginBottom: 5}}>Unique ID <small style={{color: 'red'}}>*</small></div>
                            <Input
                                placeholder='Part Unique ID'
                                disabled={fieldsDisabled}
                                value={partDetails.part_unique_id}
                                status={errors.part_unique_id?.type == 'required' && "error"}
                                {...register("part_unique_id", {required: true, value: partDetails.part_unique_id ,onChange: (e) => editFields('part_unique_id', e.target.value)})}
                            />
                        </div>
                        <div>
                            <div style={{fontSize:13, marginBottom: 5}}>Part Name <small style={{color: 'red'}}>*</small></div>
                            <Input
                                placeholder='Part Name'
                                disabled={fieldsDisabled}
                                value={partDetails.part_name}
                                status={errors.part_name?.type == 'required' && "error"}
                                {...register("part_name", {required: true, value: partDetails.part_name, onChange: (e) => editFields('part_name', e.target.value)})}
                            />
                        </div>
                        <div>
                            <div style={{fontSize:13, marginBottom: 5}}>Quantity<small style={{color: 'red'}}>*</small></div>
                            <Input
                                placeholder='Quantity'
                                disabled={fieldsDisabled}
                                value={partDetails.part_quantity}
                                status={errors.part_quantity?.type == 'required' && "error"}
                                {...register("part_quantity", {required: true, value: partDetails.part_quantity, onChange: (e) => editFields('part_quantity', e.target.value)})}
                            />
                        </div>
                        <div>
                            <div style={{fontSize:13, marginBottom: 5}}>Price<small style={{color: 'red'}}>*</small></div>
                            <Input
                                placeholder='Price'
                                disabled={fieldsDisabled}
                                value={partDetails.part_price}
                                status={errors.part_price?.type == 'required' && "error"}
                                {...register("part_price", {required: true, value: partDetails.part_price, onChange: (e) => editFields('part_price', e.target.value)})}
                            />
                        </div>
                        <div>
                            <div style={{fontSize:13, marginBottom: 5}}>Part Type<small style={{color: 'red'}}>*</small></div>
                            <Input
                                placeholder='Eg. Nos/Liter/Set'
                                disabled={fieldsDisabled}
                                value={partDetails.part_type}
                                status={errors.part_type?.type == 'required' && "error"}
                                {...register("part_type", {required: true, value: partDetails.part_type, onChange: (e) => editFields('part_type', e.target.value)})}
                            />
                        </div>
                        <div>
                            <div style={{fontSize:13, marginBottom: 5}}>Part Tax Rate<small style={{color: 'red'}}>*</small></div>
                            <Input
                                placeholder='percentage'
                                disabled={fieldsDisabled}
                                value={partDetails.part_tax_rate}
                                status={errors.part_tax_rate?.type == 'required' && "error"}
                                {...register("part_tax_rate", {required: true, value: partDetails.part_tax_rate, onChange: (e) => editFields('part_tax_rate', e.target.value)})}
                            />
                        </div>
                        <div>
                            <div style={{fontSize:13, marginBottom: 5}}>Part HSN Code<small style={{color: 'red'}}>*</small></div>
                            <Input
                                placeholder='HSN Code'
                                disabled={fieldsDisabled}
                                value={partDetails.part_hsn_code}
                                status={errors.part_hsn_code?.type == 'required' && "error"}
                                {...register("part_hsn_code", {required: true, value: partDetails.part_hsn_code, onChange: (e) => editFields('part_hsn_code', e.target.value)})}
                            />
                        </div>
                    </div>
                    <div className='customer-details-container-bottom mt-4'>
                        {editable || actionType == 'add' ?
                            <>
                                <Button variant="contained" type='submit' className='login-button' style={{marginRight: '10px'}}>Submit</Button>
                                {actionType != 'add' && <Button variant="contained" type='submit' className='login-button' style={{marginRight: '10px'}} onClick={(e) => {resetDetails(e)}}>Reset</Button>}
                                <Button variant="outlined" type='button' color="error" onClick={(e) => {toggleEditable(e)}}>Cancel</Button>
                            </>
                            :
                            <Button variant="contained" className='login-button' type="button" onClick={(e) => {toggleEditable(e)}}>Edit</Button>
                        }
                    </div>
                </form>
            </div>
        </div>
    )
}

export default PartDetailsPage