import React,{ useState} from "react";
import { Modal } from 'antd';
import Button from '@mui/material/Button';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { Input } from 'antd';
import {error, success, warning} from '../../../component/message'

import Select from 'react-select'
import Part from '../../part'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { LoadingButton } from '@mui/lab';

import PartListComponent from "../../../component/partsList";

function StandardBillingSetupComponent({
    checkIsPartSelected, 
    onChangePart, 
    selectedParts, 
    onChangeBillingFields, 
    billingObject, 
    customerOption,
    calculate_total_amount,
    calculate_cgst_tax_amount,
    calculate_sgst_tax_amount,
    calculate_total_tax_amount,
    calculate_final_amount,
    setSelectedParts,
    billingType,
    vehicleOption,
    generate_invoice,
    setBillingDetails,
    loading
}){
    console.log(customerOption, 'op')
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [quantity, setQuantity] = useState({})

    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' }
      ]

      const columns = [
        {
            name: 'S.NO',
            selector: (row, index) => index + 1,
        },
        {
            name: 'Part Unique ID',
            selector: row => row.part_unique_id,
        },
        {
            name: 'Part Name',
            selector: row => {
                return (
                    <p>{row.part_name} <span className={row.part_type == 'Nos' ? 'piece-class' : row.part_type == 'Set' ? 'set-class' : 'liter-class'}>{row.part_type}</span></p>
                )
            },
        },
        {
            name: 'Available Quantity',
            selector: row => row.part_quantity,
        },
        {
            name: 'Price',
            selector: row => row.part_price,
        },
        {
            name: 'Quantity',
            selector: (row, index) => {
                return (
                    <>  
                        <input className='custom-input-field text-center' style={{width: 50, height: 30}} value={quantity[row._id]} placeholder="0" onChange={(e) => {
                            const clone_count = {...quantity}
                            clone_count[row._id] = e.target.value
                            setQuantity(clone_count)
                        }}/>
                    </>
                )
            }
        },
        {
            name: 'Action',
            selector: (row, index) => {
                if(checkIsPartSelected(row._id)){
                    return (
                        <>  
                            <Tooltip title="Remove">
                                <IconButton onClick={() => {
                                    console.log('remove')
                                    onChangePart(row._id, row, 0, 'remove')
                                    warning(`${row.part_name} is removed, ${quantity[row._id]} quantity.`)
                                }}>
                                    <RemoveCircleOutlineIcon style={{color: 'rgba(255,0,0, 0.7)'}}/>
                                </IconButton>
                            </Tooltip>
                        </>
                    )
                }

                return(
                <>  
                    <Tooltip title="Add">
                        <IconButton onClick={() => {
                            console.log('add')
                            if(quantity[row._id]){
                                onChangePart(row._id, row, quantity[row._id], 'add')
                                success(`${row.part_name} is added, ${quantity[row._id]} quantity.`)
                            }else{
                                error('please enter part quantity')
                            }
                        }}>
                            <AddCircleOutlineIcon style={{color: 'rgba(0,255,0, 0.7)'}}/>
                        </IconButton>
                    </Tooltip>
                </>
            )
        }
        }
    ];

    const showModal = () => {
        setIsModalOpen(true);
      };
    
      const handleOk = () => {
        setIsModalOpen(false);
      };
    
      const handleCancel = () => {
        setIsModalOpen(false);
      };    

      const handleSubmit = () => {

      }

      const onSearchText = (payload) => {
        console.log(payload, 'pay')
        console.log(!payload)
        // if(payload == ""){
        //     setFilteredData(data)
        //     return
        // }
        // const find_ = filteredData.filter(x => x.customer_name.includes(payload) || x.customer_unique_id.includes(payload))
        // setFilteredData(find_)
    }

    const changePart = (row, value, field) => {
        const clone = {...selectedParts}
        if(field == 'part_quantity'){
            clone[row._id][field] = value
        }
        else{
            clone[row._id].part[field] = value
        }
        if(field != 'part_name'){
            clone[row._id].total = Math.round((clone[row._id].quantity * clone[row._id].part.part_price) || 0)
        }
        setSelectedParts(clone)
    }

    return (
        <div>
            <div className="grid grid-cols-2">
                <input 
                    className="custom-input-field"
                    placeholder="Bill No"
                    onChange={(e) => onChangeBillingFields('invoice_no', e.target.value, 'other')}
                    value={billingObject['invoice_no']}
                />
                <input 
                    className="custom-input-field"
                    type={'date'}
                    onChange={(e) => onChangeBillingFields('invoice_date', e.target.value, 'other')}
                    value={billingObject['invoice_date']}
                />
                
                <Select 
                    className="m-1 rounded-none custom-select-field" 
                    options={customerOption} 
                    placeholder="Choose Customer"
                    onChange={(e) => onChangeBillingFields('customer_uid', e.value, 'customer')}
                />

                <Select 
                    className="m-1 rounded-none custom-select-field" 
                    options={vehicleOption} 
                    placeholder="Choose Vehicle"
                    onChange={(e) => onChangeBillingFields('vehicle_uid', e.value, 'vehicle')}
                />
                <input 
                    className="custom-input-field"
                    placeholder="GSTIN"
                    onChange={(e) => onChangeBillingFields('gst_no', e.target.value, 'other')}
                    value={billingObject['gst_no']}
                />
                <input 
                    className="custom-input-field"
                    placeholder="Amount In Text"
                    onChange={(e) => onChangeBillingFields('rupees_in_text', e.target.value, 'other')}
                    value={billingObject['rupees_in_text']}
                />
                <input 
                    className="custom-input-field"
                    placeholder="Bank Name"
                    onChange={(e) => onChangeBillingFields('bank_name', e.target.value, 'bank')}
                    value={billingObject['bank_details']['bank_name']}
                />
                <input 
                    className="custom-input-field"
                    placeholder="Bank Account No"
                    onChange={(e) => onChangeBillingFields('bank_account_no', e.target.value, 'bank')}
                    value={billingObject['bank_details']['bank_account_no']}
                />
                <input 
                    className="custom-input-field"
                    placeholder="Bank IFSC Code"
                    onChange={(e) => onChangeBillingFields('bank_ifsc_code', e.target.value, 'bank')}
                    value={billingObject['bank_details']['bank_ifsc_code']}
                />
                <input 
                    className="custom-input-field"
                    placeholder="Phone Number"
                    onChange={(e) => onChangeBillingFields('phone_number', e.target.value, 'other')}
                    value={billingObject['phone_number']}
                />
                <input 
                    className="custom-input-field"
                    placeholder="Secondary Phone Number"
                    onChange={(e) => onChangeBillingFields('secondary_phone_number', e.target.value, 'other')}
                    value={billingObject['secondary_phone_number']}
                />
                <input 
                    className="custom-input-field"
                    placeholder="CGST %"
                    onChange={(e) => onChangeBillingFields('cgst_percentage', parseFloat(e.target.value || 0), 'other')}
                    value={billingObject['cgst_percentage']}
                />
                <input 
                    className="custom-input-field"
                    placeholder="SGST %"
                    onChange={(e) => onChangeBillingFields('sgst_percentage', parseFloat(e.target.value || 0), 'other')}
                    value={billingObject['sgst_percentage']}
                />
            </div>
            <div className="mt-2 p-1">
                <div className="mt-2 flex align-middle justify-end mb-2">
                    <Tooltip title="Edit Table">
                        <IconButton onClick={() => showModal()}>
                            <BorderColorIcon style={{color:"rgba(0, 0, 255, 0.5)"}}/>
                        </IconButton>
                    </Tooltip>
                </div>
                <div className="table" style={{borderLeft: '1px solid #2c204e', borderRight: '1px solid #2c204e'}}>
                    <div className='table-row header-row'>
                        <div className='text-15 table-cell cell-one bill-color' style={{width:"10%"}}>
                            S.No
                        </div>
                        <div className='text-15 table-cell cell-two bill-color' style={{width: billingType == "labour_billing" ? "30%" : "40%"}}>
                            Part Name
                        </div>
                        {billingType == "labour_billing" && <div className='text-15 table-cell cell-two bill-color' style={{width:"10%"}}>
                            HSN CODE
                        </div>}
                        <div className='text-15 table-cell cell-two bill-color' style={{width:"10%"}}>
                            Quantity
                        </div>
                        <div className='text-15 table-cell cell-two bill-color' style={{width:"10%"}}>
                            Price
                        </div>
                        {billingType == "labour_billing" && <div className='text-15 table-cell cell-two bill-color' style={{width:"10%"}}>
                            Tax (%)
                        </div>}
                        <div className={billingType == "labour_billing" ? 'text-15 table-cell cell-two bill-color' : 'text-15 table-cell cell-three bill-color'} style={{width:"10%"}}>
                            Total
                        </div>
                        {billingType == "labour_billing" && <div className='text-15 table-cell cell-three bill-color' style={{width:"10%"}}>
                            Total (with tax)
                        </div>}
                    </div>
                    {Object.keys(selectedParts).map((x, index) => {
                        const data = selectedParts[x]
                        return (
                            <div className='table-row'>
                                <div className='table-cell cell-one-body' style={{width:'10%'}}>
                                    {index + 1}
                                </div>
                                <div className='table-cell cell-two-body' style={{width:'30%'}}>
                                    <input value={data.part?.part_name} onChange={(e) => changePart(data.part, e.target.value, 'part_name')}/>
                                </div>
                                {billingType == "labour_billing" && <div className='table-cell cell-two-body' style={{width:'10%'}}>
                                    {data?.part?.part_hsn_code}
                                </div>}
                                <div className='table-cell cell-two-body' style={{width:'10%'}}>
                                    {data?.quantity}
                                </div>
                                <div className='table-cell cell-two-body' style={{width:'10%'}}>
                                    <input style={{width: '30px'}} value={data?.part?.part_price} onChange={(e) => changePart(data.part, e.target.value, 'part_price')}/>
                                </div>
                                {billingType == "labour_billing" && <div className='table-cell cell-two-body' style={{width:'10%'}}>
                                    <input style={{width: '30px'}} value={data?.part?.part_tax_rate} onChange={(e) => changePart(data.part, e.target.value, 'part_tax_rate')}/>
                                </div>}
                                <div className={billingType == "labour_billing" ? 'table-cell cell-two-body': 'table-cell cell-three-body'} style={{width:'10%'}}>
                                    {data.total}
                                </div>
                                {billingType == "labour_billing" && <div className='table-cell cell-three-body' style={{width:'10%'}}>
                                    {data.total_amount_with_tax}
                                </div>}
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className="mt-2 flex align-middle justify-between p-1">
                <div style={{width: '35%'}}>
                </div>
                <div className="flex align-middle justify-between p-1"  style={{width: '65%'}}>
                    <div className="w-2/3">
                        <div className="text-15 flex align-middle justify-end mr-1" style={{height: '50px'}}>
                            <span>Total Amount Before Tax</span>
                        </div>
                        <div className="text-15 flex align-middle justify-end mr-1" style={{height: '50px'}}>
                            <span>ADD CGST {billingType != "labour_billing" && `${billingObject.cgst_percentage}%`}</span>
                        </div>
                        <div className="text-15 flex align-middle justify-end mr-1" style={{height: '50px'}}>
                            <span>ADD SGST {billingType != "labour_billing" && `${billingObject.sgst_percentage}%`}</span>
                        </div>
                        <div className="text-15 flex align-middle justify-end mr-1" style={{height: '50px'}}>
                            <span>Total Amount GST Tax</span>
                        </div>
                        <div className="text-15 flex align-middle justify-end mr-1" style={{height: '50px'}}>
                            <span>Rounded Off</span>
                        </div>
                        <div className="text-15 flex align-middle justify-end mr-1" style={{height: '50px'}}>
                            <span>Total Amount After Tax</span>
                        </div>
                    </div>
                    <div className="w-2/5">
                        <div style={{height: '50px'}}>
                            <input
                                className="custom-input-field"
                                style={{width: '100%', height: 30}}
                                value={billingObject && billingObject.total_amount_before_tax ? billingObject.total_amount_before_tax : calculate_total_amount()}
                                onChange={(e) => setBillingDetails('total_amount_before_tax', parseFloat(e.target.value))}
                            />
                        </div>
                        <div style={{height: '50px'}}>
                            <input
                                className="custom-input-field"
                                style={{width: '100%', height: 30}}
                                value={billingObject && billingObject.cgst_tax_amount ? billingObject.cgst_tax_amount : calculate_cgst_tax_amount()}
                                onChange={(e) => setBillingDetails('cgst_tax_amount', parseFloat(e.target.value))}
                            />
                        </div>
                        <div style={{height: '50px'}}>
                            <input
                                className="custom-input-field"
                                style={{width: '100%', height: 30}}
                                value={billingObject && billingObject.sgst_tax_amount ? billingObject.sgst_tax_amount : calculate_sgst_tax_amount()}
                                onChange={(e) => setBillingDetails('sgst_tax_amount', parseFloat(e.target.value))}
                            />
                        </div>
                        <div style={{height: '50px'}}>
                            <input
                                className="custom-input-field"
                                style={{width: '100%', height: 30}}
                                value={billingObject && billingObject.total_tax_amount ? billingObject.total_tax_amount : calculate_total_tax_amount()}
                                onChange={(e) => setBillingDetails('total_tax_amount', parseFloat(e.target.value))}
                            />
                        </div>
                        <div style={{height: '50px'}}>
                            <input
                                className="custom-input-field"
                                style={{width: '100%', height: 30}}
                                value={billingObject && billingObject.round_off ? billingObject.round_off : 0}
                                onChange={(e) => setBillingDetails('round_off', parseFloat(e.target.value))}
                            />
                        </div>
                        <div style={{height: '50px'}}>
                            <input
                                className="custom-input-field"
                                style={{width: '100%', height: 30}}
                                value={billingObject && billingObject.round_off ? billingObject.round_off : billingObject.total_amount_after_tax ? billingObject.total_amount_after_tax : calculate_final_amount()}
                                onChange={(e) => setBillingDetails('total_amount_after_tax', e.target.value)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex align-middle justify-end">
                {/*<Button variant="contained" className='login-button' style={{minWidth: '100px'}} onClick={() => generate_invoice()}>GENERATE</Button>*/}
                <LoadingButton className='login-button' loading={loading} loadingIndicator="Loading…" variant="contained" onClick={() => generate_invoice()}>
                    Generate
                </LoadingButton>

            </div>
            <Modal 
                title="Parts" 
                open={isModalOpen} 
                onOk={handleOk} 
                onCancel={handleCancel}
                width={1000}
                footer={null}
                // footer={[
                //     <Button variant="contained" className='login-button' style={{minWidth: '100px', marginRight: 10}} onClick={() => handleSubmit()}>Submit</Button>,
                //     <Button variant="outlined" type='button' color="error" onClick={(e) => handleCancel()}>Cancel</Button>
                // ]}
            >
                <div>
                    {/*<div className="flex align-middle justify-end">
                        <Input.Search
                        className={"search-input-box"}
                        placeholder="Search with Name / Unique ID"
                        allowClear={true}
                        style={{width: 300}}
                        onSearch={onSearchText}
                        />
            </div>*/}
                    <div>
                        <Part 
                            partSelection={true} 
                            columns={columns}
                        />
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default StandardBillingSetupComponent