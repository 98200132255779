import React, { useEffect, useState } from 'react';
import {Input} from 'antd'
import {useNavigate} from 'react-router-dom';
import { useForm } from "react-hook-form";
import Button from '@mui/material/Button';
import request from '../utilities/api';
import { IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {error, success, warning} from '../component/message'


function CompanyDetails(){
    const [fieldsDisabled, setFieldsDisabled] = useState(false)
    const [editable, setEditable] = useState(false)
    const [user_details, setUserDetails] = useState({})
    const [user_details_clone, setUserDetailsClone] = useState({})
    const [actionType, setActionType] = useState("view")

    const { register, handleSubmit, setValue, watch, control, clearErrors, formState: { errors } } = useForm();

    console.log(errors, 'errors')

    const userDetails = JSON.parse(localStorage.getItem('user_token'))

    useEffect(() => {
        if(actionType == "view"){
            setFieldsDisabled(true)
        }else if(actionType == "edit"){
            setFieldsDisabled(false)
        }
        setUserDetails(userDetails)
        setUserDetailsClone(userDetails)
    },[actionType])

    useEffect(() => {
        setValue('company_name', user_details_clone.company_name)
        setValue('company_email', user_details.company_email)
        setValue('company_phone', user_details.company_phone)
        setValue('company_address', user_details.company_address)
        setValue('company_gst_no', user_details.company_gst_no)
        setValue('bank_name', user_details_clone.company_bank_details?.bank_name)
        setValue('bank_account_no', user_details_clone.company_bank_details?.bank_account_no)
        setValue('bank_ifsc_code', user_details_clone.company_bank_details?.bank_ifsc_code)
    }, [user_details])

    const get_company_details = (info = {}) => {
        const api_payload = {
            method: 'get',
            url_key: 'company',
            params: {}
        }
        request(api_payload)
        .then(res => {
            const response = res.data
            const {token, ...rest} = {...userDetails}
            const updated_data = {...response.data, token}
            localStorage.setItem('user_token', JSON.stringify(updated_data))
            setUserDetails(updated_data)
            setUserDetailsClone(updated_data)
        })
        .catch(err => {
            const api_response = err.response?.data
            error(api_response.message)
        })
    }

    function toggleEditable(e){
        if(e) e.preventDefault()
        if(actionType == 'add'){
            goBack()
            return
        }
        if(editable){
            setFieldsDisabled(true)
        }else{
            setFieldsDisabled(false)
        }
        setEditable(!editable)
    }

    function goBack(){
        setActionType("")
    }

    function resetDetails(e){
        e.preventDefault()
        setUserDetails({...user_details_clone})
        setValue('company_name', user_details_clone.company_name)
        setValue('company_email', user_details_clone.company_email)
        setValue('company_phone', user_details_clone.company_phone)
        setValue('company_address', user_details_clone.company_address)
        setValue('company_gst_no', user_details_clone.company_gst_no)
        setValue('bank_name', user_details_clone.company_bank_details?.bank_name)
        setValue('bank_account_no', user_details_clone.company_bank_details?.bank_account_no)
        setValue('bank_ifsc_code', user_details_clone.company_bank_details?.bank_ifsc_code)
        clearErrors()
    }

    function editFields(key, value, type){
        const clone_data = {...user_details}
        if(type == "bank_details"){
            if(clone_data["company_bank_details"]){
                clone_data["company_bank_details"][key] = value
                setValue(key, value)
            }else{
                clone_data["company_bank_details"] = {
                    [key] : value
                }
                setValue(key, value)
            }
        }else{
            clone_data[key] = value
            setValue(key, value)
        }
        setUserDetails(clone_data)
        clearErrors()
    }

    console.log(user_details, 'user_details')

    const onSubmit = (payload, e) => {
        console.log(payload, 'payload')

        if(payload["bank_name"] || payload["bank_account_no"] || payload["bank_ifsc_code"]){
            payload["company_bank_details"] = {
                bank_name: payload["bank_name"],
                bank_account_no: payload["bank_account_no"],
                bank_ifsc_code: payload["bank_ifsc_code"],
            }
            delete payload["bank_name"]
            delete payload["bank_account_no"]
            delete payload["bank_ifsc_code"]
        }

        const api_payload = {
            method: 'put',
            url_key: 'company',
            params: payload
        }
        request(api_payload)
        .then(res => {
            const response = res.data
            toggleEditable()
            get_company_details()
            success(response.message)
        })
        .catch(err => {
            const api_response = err.response?.data
            error(api_response.message)
        })
    }

    return (
        <div className='h-full w-full flex align-middle justify-center flex-col'>
            <div className='customer-details-container mt-4'>
                <h1>{actionType == 'edit' ? 'Update Company Details' : 'Company Details'}</h1>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='grid grid-cols-2' style={{gridGap: '10px'}}>
                        <div>
                            <div style={{fontSize:13, marginBottom: 5}}>Company Name <small style={{color: 'red'}}>*</small></div>
                            <Input
                                placeholder='Company Name'
                                disabled={fieldsDisabled}
                                value={user_details.company_name}
                                status={errors.company_name?.type == 'required' && "error"}
                                {...register("company_name", {required: true, value: user_details.company_name ,onChange: (e) => editFields('company_name', e.target.value)})}
                            />
                        </div>
                        <div>
                            <div style={{fontSize:13, marginBottom: 5}}>Company GSTIN NO<small style={{color: 'red'}}>*</small></div>
                            <Input
                                placeholder='Company GSTIN'
                                disabled={fieldsDisabled}
                                value={user_details.company_gst_no}
                                status={errors.company_gst_no?.type == 'required' && "error"}
                                {...register("company_gst_no", {required: true, value: user_details.company_gst_no, onChange: (e) => editFields('company_gst_no', e.target.value)})}
                            />
                        </div>
                        <div>
                            <div style={{fontSize:13, marginBottom: 5}}>Company Email<small style={{color: 'red'}}>*</small></div>
                            <Input
                                placeholder='Company Email'
                                disabled={fieldsDisabled}
                                value={user_details.company_email}
                                status={errors.company_email?.type == 'required' && "error"}
                                {...register("company_email", {required: true, value: user_details.company_email, onChange: (e) => editFields('company_email', e.target.value)})}
                            />
                        </div>
                        <div>
                            <div style={{fontSize:13, marginBottom: 5}}>Company Phone<small style={{color: 'red'}}>*</small></div>
                            <Input
                                placeholder='Company Phone'
                                disabled={fieldsDisabled}
                                value={user_details.company_phone}
                                status={errors.company_phone?.type == 'required' && "error"}
                                {...register("company_phone", {required: true, value: user_details.company_phone, onChange: (e) => editFields('company_phone', e.target.value)})}
                            />
                        </div>
                        <div>
                            <div style={{fontSize:13, marginBottom: 5}}>Bank Name<small style={{color: 'red'}}>*</small></div>
                            <Input
                                placeholder='Bank Name'
                                disabled={fieldsDisabled}
                                value={user_details.company_bank_details?.bank_name}
                                status={errors.company_bank_details?.bank_name?.type == 'required' && "error"}
                                {...register("company_bank_details.bank_name", {required: false, value: user_details.company_bank_details?.company_bank_details?.bank_name, onChange: (e) => editFields('bank_name', e.target.value, 'bank_details')})}
                            />
                        </div>
                        <div>
                            <div style={{fontSize:13, marginBottom: 5}}>Bank Account No<small style={{color: 'red'}}>*</small></div>
                            <Input
                                placeholder='Bank Account No'
                                disabled={fieldsDisabled}
                                value={user_details.company_bank_details?.bank_account_no}
                                status={errors.company_bank_details?.bank_account_no?.type == 'required' && "error"}
                                {...register("company_bank_details.bank_account_no", {required: false, value: user_details.company_bank_details?.bank_account_no, onChange: (e) => editFields('bank_account_no', e.target.value, 'bank_details')})}
                            />
                        </div>
                        <div>
                            <div style={{fontSize:13, marginBottom: 5}}>Bank IFSC Code<small style={{color: 'red'}}>*</small></div>
                            <Input
                                placeholder='Bank IFSC Code'
                                disabled={fieldsDisabled}
                                value={user_details.company_bank_details?.bank_ifsc_code}
                                status={errors.bank_ifsc_code?.type == 'required' && "error"}
                                {...register("bank_ifsc_code", {required: false, value: user_details.company_bank_details?.bank_ifsc_code, onChange: (e) => editFields('bank_ifsc_code', e.target.value, 'bank_details')})}
                            />
                        </div>
                    </div>
                    <div className='mt-4' style={{height: '120px'}}>
                        <div style={{fontSize:13, marginBottom: 5}}>Company Address<small style={{color: 'red'}}>*</small></div>
                        <Input.TextArea
                            placeholder='Company Address'
                            disabled={fieldsDisabled}
                            style={{height: '80px'}}
                            value={user_details.company_address}
                            status={errors.company_address?.type == 'required' && "error"}
                            {...register("company_address", {required: true, value: user_details?.company_address, onChange: (e) => editFields('company_address', e.target.value)})}
                        />
                    </div>
                    <div className='customer-details-container-bottom'>
                        {editable ?
                            <>
                                <Button variant="contained" type='submit' className='login-button' style={{marginRight: '10px'}}>Submit</Button>
                                <Button variant="contained" type='submit' className='login-button' style={{marginRight: '10px'}} onClick={(e) => {resetDetails(e)}}>Reset</Button>
                                <Button variant="outlined" type='button' color="error" onClick={(e) => {toggleEditable(e)}}>Cancel</Button>
                            </>
                            :
                            <Button variant="contained" className='login-button' type="button" onClick={(e) => {toggleEditable(e)}}>Edit</Button>
                        }
                    </div>
                </form>
            </div>
        </div>
    )
}

export default CompanyDetails