import React, { useEffect, useState } from 'react';
import {Input} from 'antd'
import {useNavigate} from 'react-router-dom';
import { useForm } from "react-hook-form";
import Button from '@mui/material/Button';
import { IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import Select from 'react-select'


function VehicleDetailsPage({customerOption, actionType, setActionType, selectedVehicle, updateVehicle, addNewVehicle}){

    const [fieldsDisabled, setFieldsDisabled] = useState(false)
    const [editable, setEditable] = useState(false)
    const [vehicleDetails, setvehicleDetails] = useState({...selectedVehicle})

    const { register, handleSubmit, setValue, watch, control, clearErrors, formState: { errors } } = useForm();

    console.log(errors, 'errors')

    const options = [
        { value: 'Ramesh Kumar', label: 'Ramesh Kumar' },
        { value: 'Kathiravan', label: 'Kathiravan' },
      ]

    useEffect(() => {
        if(actionType == "view"){
            setFieldsDisabled(true)
        }else if(actionType == "edit"){
            setFieldsDisabled(false)
        }
    },[actionType])

    function toggleEditable(e){
        console.log('edit')
        e.preventDefault()
        if(actionType == 'add'){
            goBack()
            return
        }
        if(editable){
            setFieldsDisabled(true)
        }else{
            setFieldsDisabled(false)
        }
        setEditable(!editable)
    }

    function goBack(){
        setActionType("")
    }

    function resetDetails(e){
        e.preventDefault()
        setvehicleDetails({...selectedVehicle})
        setValue('vehicle_unique_id', selectedVehicle.vehicle_unique_id)
        setValue('vehicle_number', selectedVehicle.vehicle_number)
        setValue('vehicle_brand', selectedVehicle.vehicle_brand)
        setValue('vehicle_model', selectedVehicle.vehicle_model)
        setValue('customer_uid', {label: selectedVehicle.customer_uid?.customer_name, value: selectedVehicle.customer_uid?._id})
        clearErrors()
    }

    function editFields(key, value){
        const clone_data = {...vehicleDetails}
        clone_data[key] = value
        setvehicleDetails(clone_data)
        setValue(key, value)
        clearErrors()
    }

    console.log(vehicleDetails, 'vehicleDetails')

    const onSubmit = (payload, e) => {
        if(actionType == 'add'){
            addNewVehicle(payload)
            return
        }
        updateVehicle({...payload, customer_uid: payload.customer_uid.value})
    }

    return (
        <div className='h-full w-full flex align-middle justify-center flex-col'>
            <div>
                <IconButton onClick={() => goBack()}>
                    <ArrowBackIcon/>
                </IconButton>
            </div>
            <div className='customer-details-container mt-4'>
                <h1>{actionType == 'add' ? 'Add New Vehicle' : 'Vehicle Details'}</h1>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='grid grid-cols-2' style={{gridGap: '10px'}}>
                        <div>
                            <div style={{fontSize:13, marginBottom: 5}}>Unique ID <small style={{color: 'red'}}>*</small></div>
                            <Input
                                placeholder='Vehicle Unique ID'
                                disabled={fieldsDisabled}
                                value={vehicleDetails.vehicle_unique_id}
                                status={errors.vehicle_unique_id?.type == 'required' && "error"}
                                {...register("vehicle_unique_id", {required: true, value: vehicleDetails.vehicle_unique_id ,onChange: (e) => editFields('vehicle_unique_id', e.target.value)})}
                            />
                        </div>
                        <div>
                            <div style={{fontSize:13, marginBottom: 5}}>Vehicle Number <small style={{color: 'red'}}>*</small></div>
                            <Input
                                placeholder='Vehicle Number'
                                disabled={fieldsDisabled}
                                value={vehicleDetails.vehicle_number}
                                status={errors.vehicle_number?.type == 'required' && "error"}
                                {...register("vehicle_number", {required: true, value: vehicleDetails.vehicle_number, onChange: (e) => editFields('vehicle_number', e.target.value)})}
                            />
                        </div>
                        <div>
                            <div style={{fontSize:13, marginBottom: 5}}>Brand<small style={{color: 'red'}}>*</small></div>
                            <Input
                                placeholder='Brand'
                                disabled={fieldsDisabled}
                                value={vehicleDetails.vehicle_brand}
                                status={errors.vehicle_brand?.type == 'required' && "error"}
                                {...register("vehicle_brand", {required: true, value: vehicleDetails.vehicle_brand, onChange: (e) => editFields('vehicle_brand', e.target.value)})}
                            />
                        </div>
                        <div>
                            <div style={{fontSize:13, marginBottom: 5}}>Modal<small style={{color: 'red'}}>*</small></div>
                            <Input
                                placeholder='Modal'
                                disabled={fieldsDisabled}
                                value={vehicleDetails.vehicle_model}
                                status={errors.vehicle_model?.type == 'required' && "error"}
                                {...register("vehicle_model", {required: true, value: vehicleDetails.vehicle_model, onChange: (e) => editFields('vehicle_model', e.target.value)})}
                            />
                        </div>
                        <div>
                            <div style={{fontSize:13, marginBottom: 5}}>Customer Name<small style={{color: 'red'}}>*</small></div>
                            <Select 
                            control={control}
                            options={customerOption}
                            defaultValue={{label: vehicleDetails?.customer_uid?.customer_name, value: vehicleDetails?.customer_uid?._id}}
                            onChange={(e) => editFields('customer_uid', e.value)}
                            ref={register('customer_uid', {required: true, value: {label: vehicleDetails.customer_uid?.customer_name, value: vehicleDetails.customer_uid?._id}})}
                            name="customer_uid"
                            isDisabled={fieldsDisabled}
                            />
                            {/*<Input
                                placeholder='Customer Name'
                                disabled={fieldsDisabled}
                                value={vehicleDetails.customer_name}
    status={errors.customer_name?.type == 'required' && "error"}
                                
                            />*/}
                        </div>
                    </div>
                    <div className='customer-details-container-bottom mt-4'>
                        {(editable || actionType == 'add') ?
                            <>
                                <Button variant="contained" type='submit' className='login-button' style={{marginRight: '10px'}}>Submit</Button>
                                {actionType != 'add' && <Button variant="contained" type='submit' className='login-button' style={{marginRight: '10px'}} onClick={(e) => {resetDetails(e)}}>Reset</Button>}
                                <Button variant="outlined" type='button' color="error" onClick={(e) => {toggleEditable(e)}}>Cancel</Button>
                            </>
                            :
                            <Button variant="contained" className='login-button' type="button" onClick={(e) => {toggleEditable(e)}}>Edit</Button>
                        }
                    </div>
                </form>
            </div>
        </div>
    )
}

export default VehicleDetailsPage