import React, { useEffect, useState } from "react";
import {
    useNavigate
} from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';

import IconButton from '@mui/material/IconButton';
// import Popover from '@mui/material/Popover';

import { Popover } from 'antd';

function AppHeader({sideBarType, setSideBarType}){
    // const [sideBarType, setSideBarType] = useState('max')
    const [openPopOver, setOpenPopOver] = useState(false)

    const navigate = useNavigate()

    useEffect(() => {
        const sidebar = document.getElementById("app-sidebar")
        const app_body = document.getElementById("app-body")

        if(sideBarType == "max"){
            sidebar.style.width = "30vh"
            // app_body.style.width = "70vh"
        }else if(sideBarType == "min"){
            sidebar.style.width = "10vh"
            // app_body.style.width = "90vh"
        }
    },[sideBarType])

    function toggleSideBar(){
        const type = sideBarType == "min" ? "max" : "min"
        setSideBarType(type)
    }

    function togglePopOver(){
        setOpenPopOver(!openPopOver)
    }

    function logout(){
        localStorage.removeItem('user_token')
        navigate('/login')
    }

    return (
        <div className="flex align-middle justify-between w-full">
            <div className="flex align-middle justify-center">
                <IconButton onClick={() => toggleSideBar()}>
                    <MenuIcon style={{color:'#fff'}}/>
                </IconButton>
                <div className="app-name-container ml-3">
                    <span style={{color: '#fff'}}>WorkShop Management System</span>
                </div>
            </div>
            <div>
                <Popover
                    content={
                        <div className="w-full p-2 logout-button" onClick={() => logout()}>Logout</div>
                    }
                    title=""
                    trigger="click"
                    open={openPopOver}
                    placement="topRight"
                    onOpenChange={() => togglePopOver()}
                >
                    <IconButton onClick={() => togglePopOver()}>
                        <PersonIcon style={{color:'#fff'}}/>
                    </IconButton>
                </Popover>
            </div>
        </div>
    )
}

export default AppHeader