import React, {ReactFragment, useState} from 'react'
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation,
  Navigate,
  Outlet,
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Dashboard from './page/home';
import LoginPage from './page/login';
import RegisterPage from './page/register';
import CustomerPage from './page/customer'

import './App.css'
import AppHeader from './component/app-header';
import SideBar from './component/sidebar';
import PartPage from './page/part';
import VehiclePage from './page/vehicle';
import BillingPage from './page/billing';
import CompanyDetails from './page/companyDetails';
import ForgotPassword from './page/forgot';
import ChangePassword from './page/change-password';

function AuthRoute(){
  const usetoken = localStorage.getItem("user_token");
  return (
    !usetoken ? <Outlet/> : <Navigate to="/"/>
  )
}

function ProtectedRoute(){
  const [sideBarType, setSideBarType] = useState('min')

  const usetoken = localStorage.getItem("user_token");
  return (
    usetoken ? 
    (
      <>
        <div>
          <div className='app-header'>
            <AppHeader sideBarType={sideBarType} setSideBarType={setSideBarType}/>
          </div>
          <div className='app-body-container'>
            <div className='app-sidebar' id='app-sidebar'>
              <SideBar sideBarType={sideBarType}/>
            </div>
            <div className='app-body' id='app-body'>
              <Outlet/>
            </div>
          </div>
        </div>
      </>
    )
    : <Navigate to="/login"/>
  )
}

function NotFound(){
  return (
    <h1>404</h1>
  )
}

function App(){
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<AuthRoute/>}>
          <Route path="/login" element={<LoginPage/>} exact/>
          <Route path="/register" element={<RegisterPage/>} exact/>
          <Route path="/forgot" element={<ForgotPassword/>} exact/>
          <Route path="/change-password" element={<ChangePassword/>} exact/>
        </Route>
        <Route element={<ProtectedRoute/>}>
          <Route path="/" element={<Dashboard/>} exact/>
          <Route path="/customer" element={<CustomerPage/>} exact/>
          <Route path="/vehicle" element={<VehiclePage/>} exact/>
          <Route path="/part" element={<PartPage/>} exact/>
          <Route path="/billing" element={<BillingPage/>} exact/>
          <Route path="/company-details" element={<CompanyDetails/>} exact/>
        </Route>
        <Route path='*' element={<NotFound/>}/>
      </Routes>
    </BrowserRouter>
  )
}

export default App