import React from 'react'

function StandardBilling({
    billingObject,
    calculate_total_amount,
    calculate_cgst_tax_amount,
    calculate_sgst_tax_amount,
    calculate_total_tax_amount,
    calculate_final_amount,
    selectedCustomerDetails,
    selectedParts,
    selectedVehicleDetails
}){
    console.log(selectedParts, 'selectedParts')
    const formateAmount = (amount) => {
        return parseFloat(amount).toFixed(2)
    }

    console.log(selectedCustomerDetails, 'selctedcu')
    const {customer_name = "", customer_address = ""} = selectedCustomerDetails;

    const generateCustomer = () => {
        const {customer_name = "", customer_address = ""} = selectedCustomerDetails;
        return <u>{customer_name} {customer_address}</u>
    }
    return (
        <div className='bill-base-container'>
            <div className='bill-header'>
                <div className='bill-header-first-part flex align-middle justify-between'>
                    <div className='bill-color text-12'>GSTIN : { billingObject?.gst_no &&  billingObject?.gst_no}</div>
                    <div>
                        <div className='bill-color text-12'>Cell: {billingObject?.phone_number}</div>
                        <div className='flex align-middle justify-end bill-color text-12'>{billingObject?.secondary_phone_number}</div>
                    </div>
                </div>
                <div className='flex-col align-middle justify-center'>
                    <div className='flex align-middle justify-center bill-color text-15 underline'>Tax Invoice</div>
                    <div className='flex align-middle justify-center bill-color text-lg text-italic'>BEST AUTO</div>
                    <div className='flex align-middle justify-center bill-color text-normal'>MULTI BRAND CAR SERVICE</div>
                    <div className='flex align-middle justify-center bill-color text-normal'>Veerappampalayam, Bye-pass Road, ERODE - 638012</div>
                    <div className='bill-header-first-part flex align-middle justify-between'>
                        <div className='bill-color text-12'>BILL NO: {billingObject?.invoice_no ? billingObject.invoice_no : '____'}</div>
                        <div className='bill-color text-12'>DATE: {billingObject?.invoice_date ? billingObject.invoice_date : '____'}</div>
                    </div>
                </div>
            </div>
            <div className='bill-body'>
                <div className='address-container'>
                    <p className="text-warp text-12 bill-color">To.M/s {billingObject?.customer_uid ? <u>{customer_name} {customer_address}</u> : '___________________________________'}</p>
                    <div className="flex justify-between">
                        <div className="text-warp text-12 bill-color">{selectedVehicleDetails ? <u style={{marginRight: '5px'}}>{selectedVehicleDetails?.vehicle_number}</u> : '________________'}</div>
                        <div className="text-warp text-12 bill-color">GSTIN { billingObject?.gst_no ? <u>{billingObject?.gst_no}</u> : '___________________________________'}</div>
                    </div>
                </div>
                <div className='parts-container'>
                    <div className='table'>
                        <div className='table-row header-row'>
                            <div className='text-12 table-cell cell-one bill-color' style={{width:"10%"}}>
                                S.No
                            </div>
                            <div className='text-12 table-cell cell-two bill-color' style={{width:"70%"}}>
                                Description
                            </div>
                            <div className='text-12 table-cell cell-three bill-color' style={{width:"20%"}}>
                                Total
                            </div>
                        </div>
                        {Object.keys(selectedParts).map((x,i) => {
                            const data = selectedParts[x]

                            return (
                                <div className='table-row'>
                                    <div className='table-cell cell-one-body'>
                                        {i+1}
                                    </div>
                                    <div className='table-cell cell-two-body'>
                                        {data.part?.part_name}
                                    </div>
                                    <div className='table-cell cell-three-body'>
                                        {data.total}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className='bottom-container'>
                        <div className='bottom-left-container'>
                            <div className='amount-text-container' style={{height: '70%'}}>
                                <div className='text-warp text-12 bill-color'>Rupees {billingObject.rupees_in_text ? <u>{billingObject.rupees_in_text}</u> : '_______________________________________________________________________________________'}</div>
                            </div>
                            <div className='bank-details-container mt-2'>
                                <div className='text-12 bill-color'>Bank   : {billingObject.bank_details?.bank_name}</div>
                                <div className='text-12 bill-color'>A/C NO : {billingObject.bank_details?.bank_account_no}</div>
                                <div className='text-12 bill-color'>IFSC   : {billingObject.bank_details?.bank_ifsc_code}</div>
                            </div>
                        </div>
                        <div className='bottom-right-container'>
                            <div className='flex w-full align-middle justify-between'>
                                <div className='amount-label text-12 bill-color'>
                                    Total Amount Before Tax
                                </div>
                                <div className='amount-value text-12 bill-color'>
                                    {formateAmount(billingObject && billingObject.total_amount_before_tax ? billingObject.total_amount_before_tax :calculate_total_amount())}
                                </div>
                            </div>
                            <div className='flex w-full align-middle justify-between'>
                                <div className='amount-label text-12 bill-color'>
                                    ADD CGST {billingObject.cgst_percentage}%
                                </div>
                                <div className='amount-value text-12 bill-color'>
                                    {formateAmount(billingObject && billingObject.cgst_tax_amount ? billingObject.cgst_tax_amount :calculate_cgst_tax_amount())}
                                </div>
                            </div>
                            <div className='flex w-full align-middle justify-between'>
                                <div className='amount-label text-12 bill-color'>
                                    ADD SGST {billingObject.sgst_percentage}%
                                </div>
                                <div className='amount-value text-12 bill-color'>
                                    {formateAmount(billingObject && billingObject.sgst_tax_amount ? billingObject.sgst_tax_amount : calculate_sgst_tax_amount())}
                                </div>
                            </div>
                            <div className='flex w-full align-middle justify-between'>
                                <div className='amount-label text-12 bill-color'>
                                    Total Amount GST Tax
                                </div>
                                <div className='amount-value text-12 bill-color'>
                                    {formateAmount(billingObject && billingObject.total_tax_amount ? billingObject.total_tax_amount : calculate_total_tax_amount())}
                                </div>
                            </div>
                            <div className='flex w-full align-middle justify-between'>
                                <div className='amount-label text-12 bill-color'>
                                    Rounded Off
                                </div>
                                <div className='amount-value text-12 bill-color'>
                                    {formateAmount(billingObject && billingObject.round_off ? billingObject.round_off : 0)}
                                </div>
                            </div>
                            <div className='flex w-full align-middle justify-between'>
                                <div className='amount-label text-12 bill-color'>
                                    Total Amount After Tax
                                </div>
                                <div className='amount-value text-12 bill-color'>
                                    {formateAmount(billingObject && billingObject.round_off ? billingObject.round_off : billingObject.total_amount_after_tax ? billingObject.total_amount_after_tax: calculate_final_amount())}
                                </div>
                            </div>
                            <div className='sign-container text-15 text-right p-1 bill-color'>
                                For <span>BEST AUTO</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StandardBilling