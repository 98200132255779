import React, { useState } from 'react';
import {
    useLocation,
    useNavigate
} from 'react-router-dom';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import './css/login.css'
import request from '../utilities/api';
import {success, error, warning} from '../component/message'

function ForgotPassword() {
    const [fieldValues, setFieldValues] = useState({})
    const [errors, setErrors] = useState({})
    const navigate = useNavigate()

    function setValue(key, value){
        const fields = JSON.parse(JSON.stringify(fieldValues));
        fields[key] = value
        setFieldValues(fields)
        const error = errors;
        delete error[key]
        setErrors(error)
    }

    function valuesValidation(){
        const error = {}
        if(!fieldValues.company_email){
            error["company_email"] = {
                is_error: true,
                error_text: "Registered Email is required"
            }
        }
        setErrors(error)
        return {is_valid: Object.keys(error).length ? false : true}
    }

    function onSubmit(){
        const {is_valid} = valuesValidation()
        
        if(!is_valid) return
        const payload = {
            params: fieldValues,
            method: 'post',
            url_key: 'forgot',
        }
        request(payload)
        .then(res => {
            success('Mail Sent')
            setTimeout(() => {
                navigate('/')
            },[4000])
        })
        .catch(err => {
            const res = err.response
            error(res.data.message)
        })
    }

    function redirectToRegisterPage(){
        navigate('/register')
    }

    return (
        <div className='login-base-container'>
            <div className='login-box-container'>
                <h1 className='login-text'>Forgot Password</h1>
                <TextField 
                    id="standard-basic" 
                    label="Enter Registered Mail" 
                    variant="standard" 
                    error={errors.company_email ? errors.company_email.is_error : false}
                    helperText={errors.company_email ? errors.company_email.error_text : ""}
                    style={{width: "100%", marginBottom: 15}}
                    onChange={(event) => setValue("company_email", event.target.value)}
                />
                <Button variant="contained" className='login-button' onClick={() => onSubmit()}>Submit</Button>
                <div className='register-text' onClick={() => redirectToRegisterPage()}>
                    Register?
                </div>
            </div>
            {/*<SnackBar snackBar={snackBar} handleClick={handleClick} handleClose={handleClose}/>*/}
        </div>
    )
}

export default ForgotPassword