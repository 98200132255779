import axios from 'axios'
import urls from './URL'
const api_base_url = "http://localhost:5000/api" //"https://apitraining.shop/api" 
//process.env.SERVER_DOMAIN

function request(payload){
    const {method, url_key, params, _id} = payload
    let body = "data"
    if(method == 'get') body = "params"

    let url = urls[url_key]

    if(_id) url = url.replace(':id', _id)

    const user_token = JSON.parse(localStorage.getItem("user_token"))
    
    const config = {
        method,
        url,
        baseURL: api_base_url,
        [body]: params || {}
    }
    config.headers = {
        "Content-Type": "application/json; charset=utf-8",
        "Authorization": `Bearer ${user_token?.token}`
    }
    return axios.request(config)
}

export default request