import React, { useEffect, useState } from 'react';
import {Input} from 'antd'
import {useNavigate} from 'react-router-dom';
import { useForm } from "react-hook-form";
import Button from '@mui/material/Button';
import { IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


function CustomerDetailsPage({actionType, setActionType, selectedUser, updateCustomer, addNewCustomer}){

    const [fieldsDisabled, setFieldsDisabled] = useState(false)
    const [editable, setEditable] = useState(false)
    const [user_details, setUserDetails] = useState({...selectedUser})

    const { register, handleSubmit, setValue, watch, control, clearErrors, formState: { errors } } = useForm();

    console.log(errors, 'errors')

    useEffect(() => {
        if(actionType == "view"){
            setFieldsDisabled(true)
        }else if(actionType == "edit"){
            setFieldsDisabled(false)
        }
    },[actionType])

    function toggleEditable(e){
        e.preventDefault()
        if(actionType == 'add'){
            goBack()
            return
        }
        if(editable){
            setFieldsDisabled(true)
        }else{
            setFieldsDisabled(false)
        }
        setEditable(!editable)
    }

    function goBack(){
        setActionType("")
    }

    function resetDetails(e){
        e.preventDefault()
        setUserDetails({...selectedUser})
        setValue('customer_unique_id', selectedUser.customer_unique_id)
        setValue('customer_name', selectedUser.customer_name)
        setValue('customer_email', selectedUser.customer_email)
        setValue('customer_phone', selectedUser.customer_phone)
        setValue('customer_address', selectedUser.customer_address)
        clearErrors()
    }

    function editFields(key, value){
        const clone_data = {...user_details}
        clone_data[key] = value
        setUserDetails(clone_data)
        setValue(key, value)
        clearErrors()
    }

    console.log(user_details, 'user_details')

    const onSubmit = (payload, e) => {
        if(actionType == 'add'){
            addNewCustomer(payload)
            return
        }
        payload["_id"] = user_details._id
        updateCustomer(payload)
    }

    return (
        <div className='h-full w-full flex align-middle justify-center flex-col'>
            <div>
                <IconButton onClick={() => goBack()}>
                    <ArrowBackIcon/>
                </IconButton>
            </div>
            <div className='customer-details-container mt-4'>
                <h1>{actionType == 'add' ? 'Add New Customer' : 'Customer Details'}</h1>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='grid grid-cols-2' style={{gridGap: '10px'}}>
                        <div>
                            <div style={{fontSize:13, marginBottom: 5}}>Unique ID <small style={{color: 'red'}}>*</small></div>
                            <Input
                                placeholder='Customer Unique ID'
                                disabled={fieldsDisabled}
                                value={user_details.customer_unique_id}
                                status={errors.customer_unique_id?.type == 'required' && "error"}
                                {...register("customer_unique_id", {required: true, value: user_details.customer_unique_id ,onChange: (e) => editFields('customer_unique_id', e.target.value)})}
                            />
                        </div>
                        <div>
                            <div style={{fontSize:13, marginBottom: 5}}>Customer Name <small style={{color: 'red'}}>*</small></div>
                            <Input
                                placeholder='Customer Name'
                                disabled={fieldsDisabled}
                                value={user_details.customer_name}
                                status={errors.customer_name?.type == 'required' && "error"}
                                {...register("customer_name", {required: true, value: user_details.customer_name, onChange: (e) => editFields('customer_name', e.target.value)})}
                            />
                        </div>
                        <div>
                            <div style={{fontSize:13, marginBottom: 5}}>Customer Email<small style={{color: 'red'}}>*</small></div>
                            <Input
                                placeholder='Customer Email'
                                disabled={fieldsDisabled}
                                value={user_details.customer_email}
                                status={errors.customer_email?.type == 'required' && "error"}
                                {...register("customer_email", {required: true, value: user_details.customer_email, onChange: (e) => editFields('customer_email', e.target.value)})}
                            />
                        </div>
                        <div>
                            <div style={{fontSize:13, marginBottom: 5}}>Customer Phone<small style={{color: 'red'}}>*</small></div>
                            <Input
                                placeholder='Customer Phone'
                                disabled={fieldsDisabled}
                                value={user_details.customer_phone}
                                status={errors.customer_phone?.type == 'required' && "error"}
                                {...register("customer_phone", {required: true, value: user_details.customer_phone, onChange: (e) => editFields('customer_phone', e.target.value)})}
                            />
                        </div>
                    </div>
                    <div className='mt-4' style={{height: '120px'}}>
                        <div style={{fontSize:13, marginBottom: 5}}>Customer Address<small style={{color: 'red'}}>*</small></div>
                        <Input.TextArea
                            placeholder='Customer Address'
                            disabled={fieldsDisabled}
                            style={{height: '80px'}}
                            value={user_details.customer_address}
                            status={errors.customer_address?.type == 'required' && "error"}
                            {...register("customer_address", {required: true, value: user_details.customer_address, onChange: (e) => editFields('customer_address', e.target.value)})}
                        />
                    </div>
                    <div className='customer-details-container-bottom'>
                        {editable || actionType == 'add' ?
                            <>
                                <Button variant="contained" type='submit' className='login-button' style={{marginRight: '10px'}}>Submit</Button>
                                {actionType != 'add' && <Button variant="contained" type='submit' className='login-button' style={{marginRight: '10px'}} onClick={(e) => {resetDetails(e)}}>Reset</Button>}
                                <Button variant="outlined" type='button' color="error" onClick={(e) => {toggleEditable(e)}}>Cancel</Button>
                            </>
                            :
                            <Button variant="contained" className='login-button' type="button" onClick={(e) => {toggleEditable(e)}}>Edit</Button>
                        }
                    </div>
                </form>
            </div>
        </div>
    )
}

export default CustomerDetailsPage