import React, { useEffect, useState } from 'react'

function LabourTaxBilling({
    billingObject,
    calculate_total_amount,
    calculate_cgst_tax_amount,
    calculate_sgst_tax_amount,
    calculate_total_tax_amount,
    calculate_final_amount,
    selectedCustomerDetails,
    selectedParts,
    selectedVehicleDetails,
    itemInfo
}){

    console.log(selectedParts, 'selectedParts')
    const formateAmount = (amount) => {
        return parseFloat(amount).toFixed(2)
    }

    console.log(selectedCustomerDetails, 'selctedcu')
    const {customer_name = "", customer_address = ""} = selectedCustomerDetails;

    function getTotal(key, subkey){
        const total = Object.values(itemInfo).reduce((count, value) => {
            const data = subkey ? value[key][subkey] : value[key]
            return count + data
        }, 0)
        return total
    }

    return (
        <div className='bill-base-container'>
            <div className='bill-header'>
                <div className='bill-header-first-part flex align-middle justify-between'>
                    <div className='bill-color text-12'>GSTIN : { billingObject?.gst_no &&  billingObject?.gst_no}</div>
                    <div>
                        <div className='bill-color text-12'>Cell: {billingObject?.phone_number}</div>
                        <div className='flex align-middle justify-end bill-color text-12'>{billingObject?.secondary_phone_number}</div>
                    </div>
                </div>
                <div className='flex-col align-middle justify-center'>
                    <div className='flex align-middle justify-center bill-color text-15 underline'>Tax Invoice</div>
                    <div className='flex align-middle justify-center bill-color text-lg text-italic'>BEST AUTO</div>
                    <div className='flex align-middle justify-center bill-color text-normal'>MULTI BRAND CAR SERVICE</div>
                    <div className='flex align-middle justify-center bill-color text-normal'>Veerappampalayam, Bye-pass Road, ERODE - 638012</div>
                    <div className='bill-header-first-part flex align-middle justify-between'>
                        <div className='bill-color text-12'>BILL NO: {billingObject?.invoice_no ? billingObject.invoice_no : '____'}</div>
                        <div className='bill-color text-12'>DATE: {billingObject?.invoice_date ? billingObject.invoice_date : '____'}</div>
                    </div>
                </div>
            </div>
            <div className='bill-body'>
                <div className='address-container'>
                    <p className="text-warp text-12 bill-color">To.M/s {billingObject?.customer_uid ? <u>{customer_name} {customer_address}</u> : '___________________________________'}</p>
                    <div className="flex justify-between">
                        <div className="text-warp text-12 bill-color">{selectedVehicleDetails ? <u style={{marginRight: '5px'}}>{selectedVehicleDetails?.vehicle_number}</u> : '________________'}</div>
                        <div className="text-warp text-12 bill-color">GSTIN { billingObject?.gst_no ? <u>{billingObject?.gst_no}</u> : '___________________________________'}</div>
                    </div>
                </div>
                <div className='parts-container'>
                    <div className='table'>
                        <div className='table-row header-row'>
                            <div className='text-12 table-cell cell-one bill-color'>
                                S.No
                            </div>
                            <div className='text-12 table-cell cell-two bill-color'>
                                Description
                            </div>
                            <div className='text-12 table-cell cell-extra bill-color'>
                                HSE CODE
                            </div>
                            <div className='text-12 table-cell cell-extra bill-color'>
                                Qty
                            </div>
                            <div className='text-12 table-cell cell-extra bill-color'>
                                Rate
                            </div>
                            <div className='text-12 table-cell cell-extra bill-color'>
                                Taxable Value
                            </div>
                            <div className='text-12 table-cell cell-three bill-color'>
                                Total
                            </div>
                        </div>
                        {Object.keys(selectedParts).map((x,i) => {
                            const data = selectedParts[x]
                            return (
                                <div className='table-row'>
                                    <div className='table-cell cell-one-body'>
                                        {i+1}
                                    </div>
                                    <div className='table-cell cell-two-body'>
                                        {data.part?.part_name}
                                    </div>
                                    <div className='table-cell cell-extra-body'>
                                        {data.part?.part_hsn_code}
                                    </div>
                                    <div className='table-cell cell-extra-body'>
                                        {data.quantity}
                                    </div>
                                    <div className='table-cell cell-extra-body'>
                                        {data?.part?.part_price}
                                    </div>  
                                    <div className='table-cell cell-extra-body'>
                                        {data?.part?.part_tax_rate || 0}%
                                    </div>
                                    <div className='table-cell cell-three-body'>
                                        {data?.total_amount_with_tax}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className='bottom-container'>
                        <div className='bottom-left-container-labour' style={{width:'55.1%', padding: 0, display: 'flex', flexDirection: 'column'}}>
                            <div style={{width: '100%', padding: 10, height:'100%',display:'flex',flexDirection:'column',justifyContent:'space-between'}}>
                                <div className='amount-text-container'>
                                    <div className='text-warp text-12 bill-color'>Rupees {billingObject.rupees_in_text ? <u>{billingObject.rupees_in_text}</u> : '_____________'}</div>
                                </div>
                                <div className='bank-details-container mt-2'>
                                    <div className='text-12 bill-color'>Bank   : {billingObject.bank_details?.bank_name}</div>
                                    <div className='text-12 bill-color'>A/C NO : {billingObject.bank_details?.bank_account_no}</div>
                                    <div className='text-12 bill-color'>IFSC   : {billingObject.bank_details?.bank_ifsc_code}</div>
                                </div>
                            </div>
                            {/*<div className='tax-info-container' style={{width: '100%'}}>
                                <table className='table' style={{borderBottom: '0px'}}>
                                    <thead>
                                        <tr>
                                            <td rowSpan={2} className='text-10 table-cell-one'>Taxble Value</td>
                                            <td colSpan={2} className='text-10 table-cell-two'>CGST</td>
                                            <td colSpan={2} className='text-10 table-cell-three'>SGST</td>
                                            <td rowSpan={2} className='text-10 table-cell-four'>Amount</td>
                                        </tr>
                                        <tr>
                                            <td className='text-10 text-center' style={{borderRight: '1px solid #2c204e', borderBottom: '1px solid #2c204e'}}>Tax</td>
                                            <td className='text-10 text-center' style={{borderRight: '1px solid #2c204e', borderBottom: '1px solid #2c204e'}}>Amount</td>
                                            <td className='text-10 text-center' style={{borderRight: '1px solid #2c204e', borderBottom: '1px solid #2c204e'}}>Tax</td>
                                            <td className='text-10 text-center' style={{borderBottom: '1px solid #2c204e'}}>Amount</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.values(itemInfo).map((value) => {
                                            const {cgst, sgst, taxable_value, total_amount} = value
                                            return (
                                                <tr>
                                                    <td className='table-body-one border-right'>{formateAmount(taxable_value)}</td>
                                                    <td className='table-body-two border-right'>{cgst.tax}%</td>
                                                    <td className='table-body-three border-right'>{formateAmount(cgst.amount)}</td>
                                                    <td className='table-body-four border-right'>{sgst.tax}%</td>
                                                    <td className='table-body-five border-right'>{formateAmount(sgst.amount)}</td>
                                                    <td className='table-body-six'>{formateAmount(total_amount)}</td>
                                                </tr>
                                            )
                                        })}
                                        <tr>
                                            <td className='table-body-one border-right border-top'>{formateAmount(getTotal('taxable_value'))}</td>
                                            <td className='table-body-two border-right border-top'></td>
                                            <td className='table-body-three border-right border-top'>{formateAmount(getTotal('cgst', 'amount'))}</td>
                                            <td className='table-body-four border-right border-top'></td>
                                            <td className='table-body-five border-right border-top'>{formateAmount(getTotal('sgst', 'amount'))}</td>
                                            <td className='table-body-six border-top'>{formateAmount(getTotal('total_amount'))}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                    </div>*/}
                        </div>
                        <div className='bottom-right-container-labour' style={{width:'44.9%'}}>
                            <div className='flex w-full align-middle justify-between'>
                                <div className='amount-label text-12 bill-color left-label'>
                                    Total Amount Before Tax
                                </div>
                                <div className='amount-value text-12 bill-color right-label'>
                                    {formateAmount(billingObject && billingObject.total_amount_before_tax ? billingObject.total_amount_before_tax :calculate_total_amount())}
                                </div>
                            </div>
                            <div className='flex w-full align-middle justify-between'>
                                <div className='amount-label text-12 bill-color left-label'>
                                    ADD CGST
                                </div>
                                <div className='amount-value text-12 bill-color right-label'>
                                    {formateAmount(billingObject && billingObject.cgst_tax_amount ? billingObject.cgst_tax_amount :calculate_cgst_tax_amount())}
                                </div>
                            </div>
                            <div className='flex w-full align-middle justify-between'>
                                <div className='amount-label text-12 bill-color left-label'>
                                    ADD SGST
                                </div>
                                <div className='amount-value text-12 bill-color right-label'>
                                    {formateAmount(billingObject && billingObject.sgst_tax_amount ? billingObject.sgst_tax_amount : calculate_sgst_tax_amount())}
                                </div>
                            </div>
                            <div className='flex w-full align-middle justify-between'>
                                <div className='amount-label text-12 bill-color left-label'>
                                    Total Amount GST Tax
                                </div>
                                <div className='amount-value text-12 bill-color right-label'>
                                    {formateAmount(billingObject && billingObject.total_tax_amount ? billingObject.total_tax_amount : calculate_total_tax_amount())}
                                </div>
                            </div>
                            <div className='flex w-full align-middle justify-between'>
                                <div className='amount-label text-12 bill-color left-label'>
                                    Rounded Off
                                </div>
                                <div className='amount-value text-12 bill-color right-label'>
                                    {formateAmount(billingObject && billingObject.round_off ? billingObject.round_off : 0)}
                                </div>
                            </div>
                            <div className='flex w-full align-middle justify-between'>
                                <div className='amount-label text-12 bill-color left-label'>
                                    Total Amount After Tax
                                </div>
                                <div className='amount-value text-12 bill-color right-label'>
                                    {formateAmount(billingObject && billingObject.round_off ? billingObject.round_off : billingObject.total_amount_after_tax ? billingObject.total_amount_after_tax: calculate_final_amount())}
                                </div>
                            </div>
                            <div className='sign-container text-15 text-right p-1 bill-color' style={{minHeight: '80px'}}>
                                For <span>BEST AUTO</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LabourTaxBilling