export default {
    "login": "/auth/login",
    "register": "/auth/register",
    "forgot": "/auth/forgot-password",
    "change-password": "/auth/change-password",
    "customers": "/customers",
    "get_customer": "/customers/:id",
    "update_customer": "/customers/:id",
    "delete_customer": "/customers/:id",
    "parts": "/parts",
    "get_part": "/parts/:id",
    "update_part": "/parts/:id",
    "delete_part": "/parts/:id",
    "vehicles": "/vehicles",
    "get_vehicle": "/vehicles/:id",
    "update_vehicle": "/vehicles/:id",
    "delete_vehicle": "/vehicles/:id",
    'company': "/company",
    'invoice': "/invoice",
    'invoice-mail': '/invoice/send-mail',
    'vehicles-count': '/invoice/count',
}